<template>
    <div class="schedule-flow-modal-container">
        <Modal
            title="Schedule Flow"
            primaryButtonTitle="Confirm"
            secondaryButtonTitle="Cancel"
            :showPrimaryButtonLoadingSpinner="saving"
            @secondary-button-clicked="onClose"
            @primary-button-clicked="onSave()"
            size="medium"
        >
            <LoadingSpinner v-if="loading" />
            <div v-else>
                <div class="content">
                    You're scheduling the following:
                </div>
                <div>
                    <span class="flow-label">Flow: </span><span>{{ renderedData.name }}</span>
                </div>
                <div>
                    <span class="flow-label">School: </span
                    ><span>{{ renderedData.schoolName }}</span>
                </div>
                <div v-if="renderedData.studentFilter">
                    <div class="flow-label">Filters Applied:</div>
                    <div class="chips-container">
                        <div class="label" v-if="showTagRelationship">
                            <div class="chip">
                                Tag Relationship: {{ renderedData.studentFilter.tagRelationship }}
                            </div>
                        </div>
                        <div
                            class="label"
                            :key="tag.name"
                            v-for="tag in renderedData.studentFilter.tagsRef"
                        >
                            <div class="chip">Tag: {{ tag.name }}</div>
                        </div>
                        <div
                            class="label"
                            :key="phase.name"
                            v-for="phase in renderedData.studentFilter.phases"
                        >
                            <div class="chip">Phase: {{ phase.name }}</div>
                        </div>
                        <div
                            class="label"
                            v-if="typeof renderedData.studentFilter.firstTimeEngager === 'boolean'"
                        >
                            <div class="chip">
                                First Time Engager:
                                {{ renderedData.studentFilter.firstTimeEngager ? 'Yes' : 'No' }}
                            </div>
                        </div>
                        <div
                            class="label"
                            :key="riskLevel.name"
                            v-for="riskLevel in renderedData.studentFilter.riskLevels"
                        >
                            <div class="chip">Risk Level: {{ riskLevel.name }}</div>
                        </div>
                        <div class="label">
                            <div class="chip" v-if="renderedData.studentFilter.riskCategory">
                                Risk Driver: {{ renderedData.studentFilter.riskCategory }}
                            </div>
                        </div>
                        <div class="label" v-if="renderedData.studentFilter.riskBucketRef">
                            <div class="chip">
                                Risk Driver:
                                {{ renderedData.studentFilter.riskBucketRef.category }}:
                                {{ renderedData.studentFilter.riskBucketRef.name }}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <span class="flow-label">Students: </span>
                    <span>{{ renderedData.studentCount }}</span>
                </div>
                <div>
                    <span class="flow-label">Date: </span>
                    <span>{{ renderedData.date }}</span>
                </div>
                <div class="content">
                    Review the information above to make sure it is correct.
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components-deprecated/global/Modal';
import { findById } from '@/api/chatbot-flow';
import { findStudentFilterById } from '@/api/student-filter';
import { formatLong } from '@/lib/dates';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import { isUIFeatureFlagEnabled } from '@/lib/feature-flag';

const PHASES = [
    { name: 'Adjustment', id: 'ADJUSTMENT' },
    { name: 'Growth', id: 'GROWTH' },
    { name: 'Preparing to leave', id: 'PREPARING_TO_LEAVE' },
    { name: 'Announcements', id: 'ANNOUNCEMENTS' },
    { name: 'No Phase', id: 'NO_PHASE' }
];

const RISK_LEVELS = [
    { name: 'High', id: 'HIGH_RISK' },
    { name: 'Medium', id: 'MEDIUM_RISK' },
    { name: 'Low', id: 'LOW_RISK' }
];

export default {
    name: 'ScheduleFlowModal',
    components: { LoadingSpinner, Modal },
    props: {
        // either chatbotFlowId or chatbotFlow is required
        chatbotFlowId: {
            type: String,
            required: false
        },
        chatbotFlow: {
            type: Object,
            required: false
        },
        saving: {
            type: Boolean,
            default: false
        }
    },
    async created() {
        // If this.chatbotFlowId is defined, fetch the flow and filter. Otherwise,
        // use this.chatbotFlow:
        if (this.chatbotFlowId) {
            this.flow = await findById({
                id: this.chatbotFlowId,
                includeFull: true,
                errorHandlerOptions: { rethrow: false, enableAlert: true }
            });

            if (this.flow.studentFilter) {
                this.filter = await findStudentFilterById(this.flow.studentFilter.id);
            }
        } else if (this.chatbotFlow) {
            this.flow = this.chatbotFlow;
            if (this.chatbotFlow.studentFilter) {
                this.filter = this.chatbotFlow.studentFilter;
            }
        } else {
            this.$Alert.alert({
                type: 'error',
                message: 'There was a problem, please try again.',
                timeout: 10000
            });
            this.onClose();
        }

        this.loading = false;
    },
    data() {
        return {
            flow: null,
            filter: null,
            constPhases: PHASES,
            constRiskLevels: RISK_LEVELS,
            loading: true
        };
    },
    methods: {
        onSave() {
            this.$emit('onSave', this.renderedData.studentCount);
        },
        onClose() {
            this.$emit('onClose');
        }
    },
    computed: {
        renderedData() {
            let studentFilter = null;
            let studentCount = 0;

            if (this.filter) {
                studentFilter = {
                    tagsRef: this.filter.tagsRef,
                    tagRelationship: this.filter.tagRelationship,
                    phases: [],
                    riskLevels: [],
                    firstTimeEngager:
                        typeof this.filter.firstTimeEngager === 'boolean'
                            ? this.filter.firstTimeEngager
                            : null,
                    riskCategory: this.filter.riskCategory || null,
                    riskBucketRef: this.filter.riskBucketRef || null
                };
                if (Array.isArray(this.filter.phases) && this.filter.phases.length > 0) {
                    studentFilter.phases = this.constPhases.filter(phase =>
                        this.filter.phases.includes(phase.id)
                    );
                }
                if (Array.isArray(this.filter.riskLevels) && this.filter.riskLevels.length > 0) {
                    studentFilter.riskLevels = this.constRiskLevels.filter(risk =>
                        this.filter.riskLevels.includes(risk.id)
                    );
                }

                studentCount = this.filter.studentCount;
            } else if (this.flow.studentList) {
                studentCount = this.flow.studentList.studentCount;
            }
            return {
                name: this.flow.template.name,
                schoolName: this.flow.schoolName,
                date: formatLong(this.flow.date),
                studentFilter,
                studentCount
            };
        },
        showTagRelationship() {
            return (
                typeof this.filter === 'object' &&
                Array.isArray(this.filter.tagsRef) &&
                this.filter.tagsRef.length >= 2
            );
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.schedule-flow-modal-container {
    .flow-label {
        font-weight: bold;
    }
    .chips-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 0.75rem;

        .chip {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            border: $base-border-width solid $primary-brand-color;
            padding: 0 1rem;
            margin-right: 0.4rem;
            border-radius: 1.15rem;
            font-family: $base-font-family;
            font-weight: bold;
            font-size: 1rem;
            height: 2.3rem;
            white-space: nowrap;

            .label {
                max-width: 20rem;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .content {
        margin-bottom: 5px;
        margin-top: 5px;
    }
}
</style>
