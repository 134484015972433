<template>
    <div class="checkbox-container" :class="{ disabled }">
        <label>
            <input
                type="checkbox"
                :disabled="disabled"
                :checked="modelValue"
                @input="$emit('update:modelValue', $event.target.checked)"
                :aria-label="ariaLabel"
            />
            <span class="checkmark"></span>
            <span class="label-text" v-if="label">{{ label }}</span>
        </label>
    </div>
</template>

<script>
export default {
    name: 'Checkbox',
    props: {
        // auto-bound to v-model
        modelValue: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            required: false
        },
        ariaLabel: {
            type: String,
            default: 'Checkbox'
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.checkbox-container {
    &.disabled {
        label {
            cursor: default;
        }
    }

    label {
        cursor: pointer;

        // display: inline-flex (instead of display: flex) ensures the clickable part of the label is only as wide as the content of the label.
        // vertical-align: top makes the appearance match that of display: flex
        display: inline-flex;
        vertical-align: top;

        align-items: center;
        position: relative;

        .checkmark {
            display: block;
            position: relative;
            height: 1.4rem;
            width: 1.4rem;
            background-color: $white;
            margin-right: 0;
            border: $base-border-width solid $dark-gray;
            border-radius: 0.4rem;

            &:after {
                display: none;
                content: '';
                position: absolute;

                left: 0.44rem;
                top: 0.125rem;
                width: 0.3rem;
                height: 0.7rem;
                border: solid white;
                border-width: 0 0.17rem 0.17rem 0;
                transform: rotate(45deg);
            }
        }

        input {
            position: absolute;
            opacity: 0;

            &:checked ~ .checkmark {
                border: none;
                background-color: $primary-brand-color;

                &:after {
                    display: block;
                }
            }
        }

        .label-text {
            margin-left: 15px;
            user-select: none;
        }
    }
}
</style>
