<template>
    <div class="container">
        <div class="header">
            <svg
                class="header__type"
                aria-hidden="true"
                :class="[insight.userFriendlyType === 'Overall Insight' ? 'overall-risk-icon' : '']"
            >
                <use :xlink:href="insightTypeImages[insight.userFriendlyType]" />
            </svg>
            <h3>{{ insight.userFriendlyType }}</h3>

            <p class="header__date">Date Triggered: {{ dateToRender }}</p>
        </div>

        <div class="details">
            <ul class="details__item" v-if="insight.tag">
                <li class="details__item__label-group">
                    Tag Group:&nbsp;
                    <span>{{ insight.tag.category }}</span>
                </li>
                <li class="details__item__label-group ml-2">
                    Tag:&nbsp;
                    <span>{{ insight.tag.name }}</span>
                </li>
            </ul>
            <ul class="details__item" v-if="insight.riskBucket">
                <li class="details__item__label-group">
                    Driver:&nbsp;
                    <span>{{ insight.riskBucket.name }}</span>
                </li>
            </ul>
            <ul
                class="details__item"
                :class="[insight.riskDifference && insight.riskDifference > 0 ? 'high-risk' : '']"
            >
                <li class="risk-text-container">Risk:&nbsp;</li>
                <li class="risk-diff-container">
                    <img
                        src="@/assets/low-risk-arrow.png"
                        class="risk-arrow"
                        alt="Low risk arrow"
                        v-if="insight.riskDifference < 0"
                    />
                    <img
                        src="@/assets/high-risk-arrow.png"
                        class="risk-arrow"
                        alt="High risk arrow"
                        v-if="insight.riskDifference > 0"
                    />
                    {{ riskString }}
                </li>
            </ul>
        </div>

        <div class="description">
            <p>{{ insight.description }}</p>
        </div>

        <div v-if="!detailed" class="view-details">
            <button class="text-button" @click="toDetailPage">View Insight details</button>
        </div>

        <div v-if="detailed" class="risk-details">
            <div v-if="insight.chartType === 'FLAT_BAR'" class="graph-container">
                <div class="insight-title">{{ insight.chartTitle }}</div>
                <RiskDistributionChart
                    :scores="insight.scores"
                    :type="insight.templateType"
                    :labels="insight.chartLabels"
                    :riskDriver="insight && insight.riskBucket ? insight.riskBucket.name : ''"
                />
            </div>
            <div v-if="insight.explanation" class="insight-explanation">
                <h3 :style="{ color: ' #000', margin: 0 }">More About this Insight</h3>
                <span>{{ insight.explanation }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

import Insight from '@/services/insights';

import RiskDistributionChart from './charts/RiskDistributionChart';

export default {
    name: 'InsightComponent',
    components: {
        RiskDistributionChart
    },
    props: {
        insight: {
            type: Insight,
            required: true
        },
        detailed: false
    },
    data() {
        return {
            insightTypeImages: Insight.typeImages
        };
    },
    created() {},
    computed: {
        riskString() {
            if (!this.insight.riskDifference) {
                return '';
            } else {
                const difference = Math.round(this.insight.riskDifference * 100);
                const text = difference < 0 ? 'lower' : 'higher';
                return `${Math.abs(difference)}% ${text} risk`;
            }
        },
        dateToRender() {
            return moment(this.insight.datetimeCreated).format('ll');
        },

        //* loops through insights score object and places individual score value into an array of labels to be served to Insights bar graph
        computeBarGraphLabels() {
            const graphLabels = [];
            for (let [key] of Object.entries(this.insight.scores)) {
                graphLabels.push(key);
            }
            return graphLabels;
        },

        //* loops through insights score object and places individual score value into an array of score values to be served to Insights bar graph
        computeBarGraphData() {
            const graphData = [];
            for (let [key, value] of Object.entries(this.insight.scores)) {
                graphData.push(Number(Math.round(value.value)));
            }
            return graphData;
        },
        //
        datacollection() {
            let datacollection = {
                // * labels that appear at left of graph
                labels: this.computeBarGraphLabels,
                datasets: [
                    {
                        // *Graph title
                        label: this.insight.chartTitle,
                        backgroundColor: '#008CFF',
                        pointBackgroundColor: 'white',
                        borderWidth: 1,
                        pointBorderColor: '#249EBF',
                        barPercentage: 0.15,
                        // *graph item point on graph
                        data: this.computeBarGraphData
                    }
                ]
            };
            return datacollection;
        }
    },
    methods: {
        toDetailPage() {
            // Navigate to detail page for this insight
            this.$router.push({ name: 'InsightDetail', params: { id: this.insight.id } });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../styles/variables';

.container {
    display: flex;
    flex-direction: column;
    border: 1px solid $white-smoke;
    border-radius: 0.5rem;
    padding: 2rem;
}

.header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    h3 {
        font-size: 1.3rem;
    }

    &__type {
        height: 3rem;
        width: 3rem;
        margin-right: 3rem;
        border-radius: 50%;
        color: $edsights-blue;
        background-color: $edsights-blue;
    }

    &__date {
        color: $dark-gray;
        margin-left: auto;
        margin-right: 3rem;
    }
}

.details {
    margin-left: 6rem;
    margin-bottom: 1rem;

    &__item {
        display: flex;
        margin-bottom: 1.5rem;

        &__label-group {
            display: flex;
            justify-content: start;
        }

        &__label {
            margin-right: 0.75rem;
        }

        span {
            color: $primary-dark;
            background-color: rgba($primary-dark, 0.1);
            border-radius: 0.5rem;
            padding: 0rem 0.5rem;
            margin-right: 0.5rem;
            font-weight: bold;
        }
    }
}

.high-risk {
    span {
        color: $color-high-risk;
    }
}

.description {
    margin-left: 6rem;
    font-size: 1.3rem;
}

.view-details {
    margin-left: 6rem;
    margin-top: 1rem;
    color: $dark-gray;

    p {
        cursor: pointer;
        width: 15rem;
    }

    a {
        color: unset;
        cursor: pointer;
    }
}

.risk-details {
    margin-top: 3rem;
}

.insight-title {
    font-weight: 500;
    display: flex;
    width: 15rem;
    justify-content: flex-start;
}

.graph-container {
    display: flex;
    height: 100%;
    align-items: baseline;
    margin-left: 6rem;
}

.bar-graph-container {
    display: flex;
    width: 100%;
    justify-content: center;
    height: 300px;
}

.insight-explanation {
    padding: 3rem;
    margin-top: 3rem;
    margin-left: 3rem;
    background-color: rgba(#068bff, 0.07);
    white-space: pre-line;
}

.risk-arrow {
    height: 1.2rem;
    width: 1rem;
}

// The overall risk icon was constructed differently from the other risk icons, so it needs specific styles.
.overall-risk-icon {
    background-color: white;
}

.ml-2 {
    margin-left: 2rem;
}

.risk-text-container {
    all: unset;
}

.risk-diff-container {
    all: unset;
    background-color: unset;
    justify-content: center;
    padding-left: 0;
}
</style>
