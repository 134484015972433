<template>
    <div class="knowledge-base__body container">
        <div class="manage-admins">
            <ButtonBack></ButtonBack>

            <ScrollWrapper class="box">
                <div class="header-container">
                    <h1 class="h2">Your Knowledge Base</h1>
                    <router-link class="navigation__item" :to="{ name: 'Chatbot' }" target="_blank">
                        <span class="navigation__item__text">Test Chatbot</span>
                    </router-link>
                </div>
                <LoadingSpinner v-if="loading" />
                <List v-else>
                    <template v-slot:list-actions>
                        <div class="list-actions-override">
                            <div class="actions-left">
                                <div class="input-group search-bar">
                                    <input
                                        :placeholder="'Search questions and answers'"
                                        aria-label="Search questions and answers"
                                        :value="questionAnswerSearchText"
                                        @input="searchQuestionAnswerText($event.target.value)"
                                    />
                                    <svg class="icon-search" viewBox="0 0 50 50">
                                        <use xlink:href="#icon-search" />
                                    </svg>
                                </div>
                                <div class="input-container">
                                    <DropdownV2
                                        :options="topics"
                                        :selectedOptionValue="selectedTopic.value"
                                        label="Filter by Topic"
                                        @onChange="topicChangeHandler"
                                    />
                                </div>

                                <div class="input-container">
                                    <DropdownV2
                                        :options="status"
                                        :selectedOptionValue="selectedStatus.value"
                                        label="Status filter"
                                        @onChange="statusChangeHandler"
                                    />
                                </div>
                            </div>
                            <div>
                                <Button class="admins-list__btn-add" @click="approveAllHandler">
                                    {{ approveButtonText }}
                                </Button>
                                <Button class="admins-list__btn-add" @click="addNewHandler">
                                    Add New
                                </Button>
                            </div>
                        </div>
                    </template>

                    <template v-slot:list-header>
                        <Faqv2ListItemHeader
                            :selected="allFaqsSelected"
                            @select="toggleAllFaqs(true)"
                            @unselect="toggleAllFaqs(false)"
                        />
                    </template>

                    <template v-slot:list-items>
                        <div v-if="filteredFaqs.length === 0" class="no-questions-container">
                            No matching questions
                        </div>

                        <Faqv2ListItem
                            v-else
                            v-for="faq in filteredFaqs"
                            :key="faq.id"
                            :faq="faq"
                            @updateFaqStatus="updateFaqStatus"
                        />
                    </template>
                </List>
            </ScrollWrapper>
        </div>

        <ModalAddEditFaqV2 :faqTopics="faqTopics" @refresh-faqs="updateFaqList" />
        <ModalRemoveFaqV2 @refresh-faqs="listFaqsBySchool" />
        <ModalApproveAllFaqV2
            @refresh-faqs="listFaqsBySchool"
            :filteredFaqs="filteredFaqs"
            :faqsAreFiltered="faqsAreFiltered"
        />
    </div>
</template>
<script>
import { debounce } from '@/services/utils';
import ButtonBack from '@/components-deprecated/ButtonBack';
import ScrollWrapper from '@/components-deprecated/ScrollWrapper';
import List from '@/components-deprecated/tables/List';
import Faqv2ListItemHeader from '@/views/KnowledgeBasev2/Faqv2ListItemHeader';
import Faqv2ListItem from '@/views/KnowledgeBasev2/Faqv2ListItem';
import * as FAQTopicApi from '@/api/faq-topics';
import * as FAQv2Api from '@/api/faqs-v2';
import { isNextGenAIChatbotEnabled } from '@/lib/feature-flag';
import ModalAddEditFaqV2 from '@/views/KnowledgeBasev2/ModalAddEditFaqV2';
import ModalRemoveFaqV2 from '@/views/KnowledgeBasev2/ModalRemoveFaqV2';
import ModalApproveAllFaqV2 from '@/views/KnowledgeBasev2/ModalApproveAllFaqV2';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner.vue';
import DropdownV2 from '@/components-deprecated/global/v2/DropdownV2.vue';

export default {
    name: 'KnowledgeBasev2',
    components: {
        DropdownV2,
        ButtonBack,
        ModalAddEditFaqV2,
        ModalRemoveFaqV2,
        ModalApproveAllFaqV2,
        ScrollWrapper,
        List,
        Faqv2ListItemHeader,
        Faqv2ListItem,
        LoadingSpinner
    },
    data() {
        return {
            loading: true,
            faqs: [],
            faqTopics: [],
            nextGenAIChatbotEnabled: null,
            allFaqsSelected: false,
            selectedFaqs: [],
            selectedTopic: { value: 'view-all', label: 'View all questions' },
            selectedStatus: { value: 'view-all', label: 'View all' },
            statusValue: {
                pending: 'Pending',
                approved: 'Approved'
            },
            questionAnswerSearchText: ''
        };
    },
    async created() {
        await Promise.all[(this.checkIfNextGenAIEnabled(), this.listFaqsBySchool())];
        this.searchQuestionAnswerText = debounce(this.searchQuestionAnswerText, 700);
    },
    computed: {
        topics() {
            const placeholder = {
                label: 'View all questions',
                value: 'view-all'
            };
            return [
                placeholder,
                ...this.faqTopics.map(t => {
                    return {
                        label: t.name,
                        value: t.id
                    };
                })
            ];
        },
        status() {
            const placeholder = {
                label: 'View all',
                value: 'view-all'
            };
            return [
                placeholder,
                ...[this.statusValue.approved, this.statusValue.pending].map(t => {
                    return {
                        label: t,
                        value: t
                    };
                })
            ];
        },
        selectedTopicLabel() {
            return this.selectedTopic.label;
        },
        selectedStatusLabel() {
            return this.selectedStatus.label;
        },
        selectedFaqsCount() {
            const totalFaqsLength = this.faqs.length;
            const selectedFaqsLength = this.selectedFaqs.length;

            if (this.allFaqsSelected) {
                const suffix = totalFaqsLength === 1 ? '' : 's';
                return `${totalFaqsLength} faq${suffix} selected`;
            }

            if (selectedFaqsLength) {
                const suffix = selectedFaqsLength === 1 ? '' : 's';
                return `${selectedFaqsLength} faq${suffix} selected`;
            }

            return '';
        },
        filteredFaqs() {
            const topicFilterFn = faq => {
                if (this.selectedTopic.value !== 'view-all') {
                    return faq.topicId === this.selectedTopic.value;
                }

                return true;
            };

            const statusFilterFn = faq => {
                if (this.selectedStatus.value === this.statusValue.pending) {
                    return faq.isEnabled === false;
                } else if (this.selectedStatus.value === this.statusValue.approved) {
                    return faq.isEnabled === true;
                }

                return true;
            };

            const searchFilterFn = faq => {
                if (this.questionAnswerSearchText && this.questionAnswerSearchText !== '') {
                    const searchText = this.questionAnswerSearchText.toLowerCase();
                    const faqQuestionText = (faq.questionText || '').toLowerCase();
                    const faqAnswerText = (faq.answerText || '').toLowerCase();
                    return (
                        faqQuestionText.includes(searchText) || faqAnswerText.includes(searchText)
                    );
                }

                return true;
            };

            return this.faqs
                .filter(topicFilterFn)
                .filter(statusFilterFn)
                .filter(searchFilterFn);
        },
        faqsAreFiltered() {
            return this.faqs.length !== this.filteredFaqs.length;
        },
        approveButtonText() {
            if (this.faqsAreFiltered === true) {
                return 'Approve Selected';
            }

            return 'Approve All';
        }
    },
    methods: {
        topicChangeHandler(topic) {
            this.selectedTopic = topic;
        },
        statusChangeHandler(status) {
            this.selectedStatus = status;
        },
        async searchQuestionAnswerText(value) {
            this.questionAnswerSearchText = value;
        },
        async listFaqsBySchool() {
            this.loading = true;
            this.faqTopics = await FAQTopicApi.listFaqTopics();
            this.faqs = await FAQv2Api.listFaqsv2BySchool(this.$store.state.user.schoolRef.id);
            this.loading = false;
        },
        updateFaqList(event) {
            // Refresh the FAQ list if a new FAQ is being inserted 
            if (!event.id) {
                this.listFaqsBySchool();
                return;
            }

            // Update the existing FAQ and keep the scroll position
            this.faqs = this.faqs.map(faq => faq.id === event.id ? event : faq);
        },
        async checkIfNextGenAIEnabled() {
            if (this.$store.state.user.schoolRef) {
                this.nextGenAIChatbotEnabled = await isNextGenAIChatbotEnabled(
                    this.$store.state.user.schoolRef.id
                );
            } else {
                this.nextGenAIChatbotEnabled = false;
            }
        },
        addNewHandler() {
            this.$modal.show('modal-add-edit-faq-v2', {});
        },
        toggleAllFaqs(val = !this.allFaqsSelected) {
            this.allFaqsSelected = val;
            if (this.allFaqsSelected) {
                this.selectedFaqs = this.faqs;
            } else {
                this.selectedFaqs = [];
            }
        },
        approveAllHandler() {
            this.$modal.show('modal-approve-all-faq-v2', {});
        },
        updateFaqStatus(faqId, isEnabled) {
            // update isEnabled property for an faq in state
            this.faqs = this.faqs.map(faq => (faq.id === faqId ? { ...faq, isEnabled } : faq));
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/mixins/buttons';

.container {
    min-height: 100vh;
}

.bordered {
    border: 1px solid $silver;
}

.button-remove {
    margin-right: 2rem;
}

.icon-search {
    height: 15px;
    width: 15px;
    margin-right: 0;
}

.admins-list {
    &__btn-add {
        @include base-button();
        margin-right: 15px;
        padding: 0.6rem;
    }
}

.manage-admins :deep(.scroll-wrapper__elem) {
    max-height: calc(100vh - 170px);
    min-height: 500px;
}

@media screen and (max-width: 1230px) {
    .admins-list :deep(.list) {
        width: 75%;
    }
}

:deep(.table-header) {
    justify-content: flex-end;
    top: 35px;
    position: sticky;
    z-index: 3;
    background: #fff;
}

#admin-list:last-child {
    margin-bottom: 45px;
}

.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 1rem 2px 0px;
    top: 0px;
    position: sticky;
    z-index: 3;
    background: #fff;
}

.no-questions-container {
    display: flex;
    justify-content: center;
    padding-top: 20px;
}

:deep() {
    .header {
        z-index: 2;
        top: 140px;
    }

    .table-header {
        height: auto;
        position: sticky;
        top: 36px;
        background: #fff;
        z-index: 3;
    }

    .table-header__actions-block {
        width: 100%;
        margin: 0 1rem;
    }

    .v-switch-button {
        z-index: 1 !important;
    }
}

.admins-list__btn-add {
    margin: 0 0 0 1rem;
}

.list-actions-override {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.input-container {
    width: 230px;
    position: relative;
    align-items: center;
    margin-right: 2rem;
}

.actions-left {
    display: flex;
    align-items: flex-end;
    padding: 2rem 0;
}

.input-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: $white-blue;
    padding: 0.25rem 1rem;
    border-radius: 5px;
    border: 0;
    min-height: 32px;
    width: 100%;
    color: $base-font-color;
    font-size: 12px;
    letter-spacing: 1px;

    &:focus {
        outline: 2px solid $edsights-blue;
    }

    > input {
        width: 100%;
        border: none;
        outline: none;
        background-color: $white-blue;
        padding: 0.25rem 3rem 0.25rem 1rem;
        border-radius: 5px;
        font: inherit;
        color: $input-gray;

        &:focus {
            outline: 2px solid $edsights-blue;
        }
    }
    .icon-search {
        position: absolute;
        right: 12px;
    }

    &.search-bar {
        padding: 0;
        background: transparent;
        border-radius: 5px;
        position: relative;
        max-width: 240px;
        margin-right: 2rem;
    }
}
</style>
