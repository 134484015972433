import axios from 'axios';
import moment from 'moment';

import { API_BASE, apiErrorHandler } from '../api';
import { isDbSchoolFeatureFlagEnabled } from '../../lib/feature-flag';
import store from '../../store';
import { getv2ApiBase } from '../../api/index';
import Auth from '../auth';
import CSRF from '../csrf';
import { objectToSnakeCase } from '../utils';
import options from './options';

const NODE_ALERTS_FF = 'NODE_ALERTS_ROUTE';
const ALERTS_ENDPOINT = `${API_BASE}alerts/`;
const ALERTS_ENDPOINT_V2 = getv2ApiBase('/alerts/');
//const ALERTS_ENDPOINT = `http://localhost:3003/api/v2/alerts/`;

export default class AlertAPI {
    /**
     * Initialize a `AlertAPI`` instance.
     *
     * @param {class} cls  The class to use to create Alert objects.
     **/
    constructor(cls) {
        this.cls = cls;
    }

    /**
     * Factory method to create a new instance of `AlertAPI`.
     *
     * @param {class} cls      The class to use to create Student objects.
     **/
    static create(cls) {
        return new AlertAPI(cls);
    }

    /*
     * Retrieve a list of Alerts (Answers needing review & with trigger category)
     * from staff-only endpoint.
     */
    async list(params = {}) {

        const schoolId = store.getters.user.schoolRef.id;
        const isNodeAlerts = schoolId ?  await isDbSchoolFeatureFlagEnabled(schoolId, NODE_ALERTS_FF) : false;
        const url = isNodeAlerts ? ALERTS_ENDPOINT_V2 : ALERTS_ENDPOINT;

        const options = {
            headers: { ...CSRF.getHeaders() },
            params: objectToSnakeCase(params),
            ...(await Auth.getHeaders())
        };

        const promise = axios
            .get(url, options)
            .then(response => {
                const data = response.data.results ? response.data.results : response.data;
                return {
                    ...response.data,
                    results: data.map(this.cls.fromAPI)
                };
            })
            .catch(apiErrorHandler({ apiName: 'List Alert API Error' }));

        return promise;
    }

    async getPendingAlertCount() {
        const { count } = await this.list({
            alert_statuses: options.PENDING,
            // setting page_size to 1 to cut down on payload size,
            // as that's the lowest django will accept, and we only need the count,
            // not the actual results.
            // when this moves to Node, consider an endpoint that just returns the count.
            page_size: 1
        });

        return count;
    }

    updateStatus(alertId, status) {
        const url = `${ALERTS_ENDPOINT}${alertId}/update-status/`;
        const options = {
            headers: Auth.getHeaders().headers
        };

        const data = { status: status };

        const promise = axios
            .post(url, data, options)
            .then(response => response.data)
            .catch(apiErrorHandler({ apiName: 'Update Alert Status API Error' }));

        return promise;
    }

    async export({ tagCategories }) {
        // Adding the responseType to axios here so that it is aware that it is expecting a stream of data.
        // This blob is then accessed by creating a link on the fly and programmatically clicking it so that
        // it opens a new window and executes an automatic download of the file
        // If a user can't download it may be because their pop up blocker is blocking it.

        const url = `${ALERTS_ENDPOINT}export/`;
        const options = {
            responseType: 'blob',
            headers: Auth.getHeaders().headers
        };

        const data = {
            tag_categories: tagCategories
        };

        const promise = axios.post(url, data, options).then(response => {
            const url_dl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url_dl;
            let file_name = `Alerts_${moment().format()}`;
            link.setAttribute('download', file_name + '.csv');
            link.click();
        });

        return promise;
    }
}
