<template>
    <div class="container">
        <div class="manage-tags">
            <h1 class="h2">{{ title }}</h1>
            <BoxContainer class="manage-tags__content" hide-splitter>
                <LoadingSpinner v-if="loading" />

                <div v-if="!loading && tagCategories.list.length" class="manage-tags__tags">
                    <div class="toolbar">
                        <div class="right-action-items">
                            <div class="search-tags">
                                <SearchInput @update:modelValue="onTagSearch" />
                            </div>

                            <Button @click="onClickAddNew">Add New</Button>
                        </div>
                    </div>
                    <TagListItemHeader />
                    <template
                        v-for="[key, tagCategories] of Object.entries(organizedTagCategories)"
                        :key="key"
                    >
                        <div v-if="key === 'public' && showPublicTags" class="manage-tags__tags-title">
                            <span>EdSights Tags</span>
                        </div>
                        <div v-if="key === 'custom'" class="manage-tags__tags-title">
                            <span>Your Custom Tags</span>
                        </div>
                        <template
                            v-for="(tagCategory, i) in tagCategories"
                            :key="i + '' + tagCategory.id"
                        >
                            <button
                                class="manage-tags__tag-category"
                                @click.prevent="toggleSelectedCategory(tagCategory)"
                            >
                                <div class="category-header">
                                    <h3 class="category-name">{{ tagCategory.name }}</h3>
                                    <span class="category-tag-count"
                                        >{{ tagCategory.tagsRef.length }} associated tags</span
                                    >
                                </div>
                                <div class="category-count" />
                                <div class="category-actions">
                                    <Button
                                        v-if="
                                            key !== 'public' &&
                                                shouldShowCategoryActions(tagCategory)
                                        "
                                        class="category-action-button"
                                        style-type="off"
                                        @click.stop="deleteCategory(tagCategory)"
                                        >Delete</Button
                                    >
                                    <Button
                                        v-if="
                                            key !== 'public' &&
                                                shouldShowCategoryActions(tagCategory)
                                        "
                                        class="category-action-button"
                                        style-type="off"
                                        @click.stop="renameCategory(tagCategory)"
                                        >Rename</Button
                                    >
                                </div>
                                <span class="category-arrow">
                                    <svg
                                        width="10px"
                                        height="10px"
                                        class="arrow"
                                        :class="{
                                            'arrow--reversed': isSelectedCategory(tagCategory)
                                        }"
                                        viewBox="0 0 5.9 17.51"
                                    >
                                        <use xlink:href="#icon-arrow-right-wide" />
                                    </svg>
                                </span>
                            </button>
                            <TransitionExpand
                                @after-expand="afterExpand"
                                @vue:updated="handleTagCategoryHook"
                                @vue:created="handleTagCategoryHook"
                            >
                                <div v-if="isSelectedCategory(tagCategory)">
                                    <template v-if="tagCategory.tagsRef.length <= 0">
                                        <span class="notice">
                                            <i
                                                >You don't have any tags associated with this
                                                category yet.</i
                                            >
                                        </span>
                                    </template>
                                    <template v-else-if="key == 'public'">
                                        <TagListItem
                                            v-for="tag in tagCategory.tagsRef"
                                            :key="tag.id"
                                            :name="tag.name"
                                            :value="tag"
                                            :student-count="tag.studentCount"
                                            @viewStudents="onClickViewStudents"
                                            @edit="onClickEdit"
                                        />
                                    </template>
                                    <template v-else>
                                        <div class="tag-actions">
                                            <div class="tag-action">
                                                <input
                                                    :id="tagCategory.id"
                                                    class="action-icon"
                                                    type="checkbox"
                                                    @click="selectAll($event, tagCategory)"
                                                />
                                                <label :for="tagCategory.id">Select All</label>
                                            </div>
                                            <button class="tag-action" @click="deleteSelected">
                                                <img
                                                    class="action-icon"
                                                    src="@/assets/actions/delete.png"
                                                    alt=""
                                                />
                                                <span>Delete</span>
                                            </button>
                                            <button class="tag-action" @click="mergeSelected">
                                                <img
                                                    class="action-icon"
                                                    src="@/assets/actions/merge.png"
                                                    alt=""
                                                />
                                                <span>Merge</span>
                                            </button>
                                            <button class="tag-action" @click="reassignSelected">
                                                <img
                                                    class="action-icon"
                                                    src="@/assets/actions/move.png"
                                                    alt=""
                                                />
                                                <span>Reassign Group</span>
                                            </button>
                                        </div>
                                        <TagListItem
                                            v-for="tag in tagCategory.tagsRef"
                                            :ref="tag.id"
                                            :key="tag.id"
                                            :name="tag.name"
                                            :value="tag"
                                            :student-count="tag.studentCount"
                                            :selectedTags="selectedTags"
                                            :tagCategoryId="tagCategory.id"
                                            @viewStudents="onClickViewStudents"
                                            @edit="onClickEdit"
                                            @selected="onSelect"
                                        />
                                    </template>
                                </div>
                            </TransitionExpand>
                        </template>
                    </template>
                    <Paginator
                        v-if="this.tagCategories.pagination.next != null"
                        :nextPage="this.tagCategories.pagination.next != null"
                        @next-page="getNextFilters"
                    />
                </div>
            </BoxContainer>
            <ModalRenameTag @refreshPage="refreshPage" />
            <ModalDeleteTag />
            <ModalAddTagOrCategory @refreshPage="refreshPage" :school="this.user.school" />
            <ModalRenameTagCategory />
            <ModalReassignTagCategory />
            <ModalDeleteTagCategory />
            <ModalMergeTags />
        </div>
    </div>
</template>

<script>
import { nextTick } from 'vue';

import BoxContainer from '@/components-deprecated/BoxContainer';
import TagListItemHeader from '@/components-deprecated/tables/TagListItemHeader';
import TagListItem from '@/components-deprecated/tables/TagListItem';
import TransitionExpand from '@/components-deprecated/TransitionExpand';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import Button from '@/components-deprecated/Button';
import ModalRenameTag from '@/components-deprecated/admin/ModalRenameTag';
import ModalDeleteTag from '@/components-deprecated/admin/ModalDeleteTag';
import ModalAddTagOrCategory from '@/components-deprecated/admin/ModalAddTagOrCategory';
import ModalReassignTagCategory from '@/components-deprecated/admin/ModalReassignTagCategory';
import ModalMergeTags from '@/components-deprecated/admin/ModalMergeTags';
import Paginator from '@/components-deprecated/Paginator';
import CollectionManager from '@/services/collectionManager';
import TagCategories from '@/services/tagCategories';
import ModalRenameTagCategory from '@/components-deprecated/admin/ModalRenameTagCategory';
import ModalDeleteTagCategory from '@/components-deprecated/admin/ModalDeleteTagCategory';
import SearchInput from '@/components-deprecated/inputs/SearchInput';
import { debounce } from '@/services/debounce';
import { mapState } from 'vuex';
import { isDbSchoolFeatureFlagEnabled } from '@/lib/feature-flag';

export default {
    name: 'ManageTags',
    components: {
        BoxContainer,
        TagListItemHeader,
        TagListItem,
        TransitionExpand,
        LoadingSpinner,
        Button,
        ModalRenameTag,
        ModalDeleteTag,
        ModalAddTagOrCategory,
        ModalReassignTagCategory,
        ModalMergeTags,
        Paginator,
        ModalRenameTagCategory,
        ModalDeleteTagCategory,
        SearchInput
    },
    data() {
        return {
            tagCategories: CollectionManager.create({
                ModelClass: TagCategories
            }),
            tags: [],
            selectedTagCategoryId: null,
            selectedTags: {
                categoryId: null,
                tags: []
            },
            loading: false,
            loadingNextPage: false,
            creatingTag: false,
            tagName: '',
            tagSearchTerm: '',
            scrollTarget: null,
	    showPublicTags: false
        };
    },

    watch: {
        tagSearchTerm() {
            if (!this.tagSearchTerm) {
                this.selectedTagCategoryId = null;
            }
        }
    },

    computed: {
        ...mapState(['user', 'staffSchoolViewEnabled']),
        filteredTagCategories() {
            if (this.tagSearchTerm) {
                let matchesSearch = tag => {
                    return tag.name.toLowerCase().includes(this.tagSearchTerm);
                };

                return this.tagCategories.list.filter(category => {
                    return category.tagsRef.some(matchesSearch);
                });
            }

            return this.tagCategories.list;
        },
        organizedTagCategories() {
            return this.filteredTagCategories.reduce(
                (acc, category) => {
                    if (category.isPublic) {
                        acc.public.push(category);
                        return acc;
                    } else {
                        acc.custom.push(category);
                        return acc;
                    }
                },
                { custom: [], public: [] }
            );
        },
        title() {
            return 'Manage Tags';
        }
    },
    mounted() {
        this.onTagSearch = debounce(this.onTagSearch, 500);
    },
    async created() {
        // pre filling  school

        if (this.staffSchoolViewEnabled) {
            // on the backend we opened staff for user to return all so instead we add it to the filter
            this.tagCategories.filters = {
                ...this.tagCategories.filters,
                schools: this.user.school
            };
        }

        const schoolId = this.$store.state.user.schoolRef.id;
        const featureFlagName = 'SHOW_PUBLIC_TAGS';
        this.showPublicTags = await isDbSchoolFeatureFlagEnabled(
	    schoolId,
	    featureFlagName
	);
        await this.refreshPage();
    },
    methods: {
        async getNextFilters() {
            this.tagCategories.addNextPage();
        },
        async refreshPage() {
            this.loadTagCategories();
            this.selectedTags.categoryId = null;
            this.selectedTags.tags = [];
        },
        onClickAddNew() {
            this.$modal.show('modal-add-tag-or-category', {
                tagCategories: this.organizedTagCategories.custom
            });
            this.focusModals();
        },
        onClickViewStudents(tag) {
            this.$router.push({ name: 'ManageStudentsByTag', params: { tag: tag.id } });
        },
        onClickEdit(tag) {
            this.$modal.show('modal-rename-tag', {
                tagId: tag.id,
                updateHandler: this.refreshPage
            });
            this.focusModals();
        },
        onSelect(tagCategoryId, tag, isChecked) {
            if (isChecked) {
                if (this.selectedTags.categoryId !== tagCategoryId) {
                    this.selectedTags.tags = [];
                    this.selectedTags.categoryId = tagCategoryId;
                }

                this.selectedTags.tags.push(tag);
            } else {
                this.selectedTags.tags = this.selectedTags.tags.filter(function(item) {
                    return item !== tag;
                });

                if (this.selectedTags.tags.length === 0) {
                    this.selectedTags.categoryId = null;
                }
            }
        },

        removeTag(tag) {
            let tagCategories = this.tagCategories.list;
            tagCategories.map(element => {
                if (element.id === tag.category) {
                    let tagsRef = element.tagsRef;
                    tagsRef.map(tagref => {
                        if (tagref.id === tag.id) {
                            tagsRef.splice(tagsRef.indexOf(tagref), 1);
                        }
                    });
                }
            });
        },

        async loadTagCategories(params = {}) {
            // will always sort by type
            params = { ...params, ordering: 'is_public' };
	    if (!this.showPublicTags) {
                params.is_public = false;
	    }
            try {
                this.loading = true;
                this.tagCategories.filters = { ...this.tagCategories.filters, ...params };
                await this.tagCategories.refresh();
            } catch (e) {
                this.$Alert.alert({
                    type: 'error',
                    message: `<h2>There was a problem loading the page. Please try again later.</h2>`,
                    timeout: 3000
                });
            } finally {
                this.loading = false;
            }
        },

        deleteCategory(tagCategory) {
            this.$modal.show('modal-delete-tag-category', {
                tagCategory,
                updateHandler: this.refreshPage
            });
            this.focusModals();
        },
        renameCategory(tagCategory) {
            this.$modal.show('modal-rename-tag-category', {
                tagCategory,
                updateHandler: this.refreshPage
            });
            this.focusModals();
        },

        toggleSelectedCategory(tagCategory) {
            if (this.loading) {
                return;
            }

            if (this.isSelectedCategory(tagCategory)) {
                this.selectedTagCategoryId = null;
            } else {
                this.selectedTagCategoryId = tagCategory.id;
            }
        },

        isSelectedCategory(tagCategory) {
            return this.selectedTagCategoryId === tagCategory.id;
        },

        shouldShowCategoryActions(tagCategory) {
            // User should not be able to delete the Other category. See TagCategoryViewSet for more details
            if (tagCategory.name === 'Other') {
                return false;
            }

            // Do not show action buttons if TagCategory is expanded
            if (this.isSelectedCategory(tagCategory)) {
                return false;
            }

            return true;
        },

        selectAll(event, tagCategory) {
            if (event.target.checked) {
                this.selectedTags.categoryId = tagCategory.id;
                this.selectedTags.tags = tagCategory.tagsRef;
            } else {
                this.selectedTags.categoryId = null;
                this.selectedTags.tags = [];
            }
        },
        deleteSelected() {
            if (this.selectedTags.tags.length == 0) {
                return;
            }

            this.$modal.show('modal-delete-tag', {
                tags: this.selectedTags.tags,
                updateHandler: this.refreshPage
            });

            this.focusModals();
        },
        mergeSelected() {
            if (this.selectedTags.tags.length <= 1) {
                return;
            }

            this.$modal.show('modal-merge-tags', {
                tags: this.selectedTags.tags,
                updateHandler: this.refreshPage
            });

            this.focusModals();
        },
        reassignSelected() {
            if (this.selectedTags.tags.length == 0) {
                return;
            }

            this.$modal.show('modal-reassign-tags', {
                tags: this.selectedTags.tags,
                updateHandler: this.refreshPage
            });

            this.focusModals();
        },
        async onTagSearch(searchTerm) {
            this.tagSearchTerm = searchTerm.toLowerCase();
        },
        afterExpand() {
            if (!this.scrollTarget) {
                return;
            }

            let elements = this.$refs[this.scrollTarget];
            if (elements) {
                let element = elements[0].$el;
                element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
            }

            this.scrollTarget = null;
        },
        handleTagCategoryHook() {
            if (this.filteredTagCategories.length === 1) {
                let tagCategory = this.filteredTagCategories[0];
                this.selectedTagCategoryId = tagCategory.id;

                let tags = tagCategory.tagsRef.filter(tag =>
                    tag.name.toLowerCase().includes(this.tagSearchTerm)
                );

                if (tags.length === 1) {
                    let tagId = tags[0].id;

                    let elements = this.$refs[tagId];
                    if (elements && elements[0]) {
                        let element = elements[0].$el;
                        element.scrollIntoView({
                            behavior: 'smooth',
                            block: 'end',
                            inline: 'nearest'
                        });
                    } else {
                        this.scrollTarget = tagId;
                    }
                }
            }
        },
        focusModals() {
            nextTick(() => {
                const focusModalOnOpen = document.querySelector('.vm--container');
                if (focusModalOnOpen) {
                    focusModalOnOpen.focus();
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.toolbar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0.5rem;

    .right-action-items {
        display: flex;

        margin-left: auto;
    }
}

.notice {
    margin: 2rem;
}

.manage-tags {
    &__scroll-wrapper :deep(.scrollcategory-actions &__content) {
        padding: 25px 45px;
    }

    &__add-new {
        width: 100%;
        padding-left: 25px;
    }

    &__btn-next {
        margin-left: 25px;
    }

    &__tags {
        padding: 2rem;
    }

    &__tags-title {
        font-weight: bolder;
        font-family: Poppins-SemiBold;
        text-transform: uppercase;
        font-size: 1.1rem;
        padding: 1rem;
    }

    &__tag-category {
        display: flex;
        cursor: pointer;
        background: #fff;
        border: 0;
        width: 100%;

        &:hover {
            background-color: $white-blue;
        }

        &:focus {
            outline: 2px solid $edsights-blue;
        }
    }
}

.search-tags {
    margin-right: 16px;
    display: flex;
    align-items: center;

    label {
        white-space: nowrap;
        font-size: 14px;
        color: black;
        font-weight: bold;
    }
}

.flex-container {
    display: flex;

    > :last-child {
        margin-left: 2rem;
    }
}

.input-wrapper {
    display: flex;
    align-items: center;
    max-width: 520px;
    margin: 15px 0;
}

.arrow {
    transform: rotate(-90deg);
    cursor: pointer;

    &--reversed {
        transform: rotate(90deg);
    }
}

.category-header {
    margin: 1rem;
    width: 25%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.category-name {
    float: left;
}

.category-count {
    margin: 1rem;
    width: 25%;
    min-width: 25%;
}

.category-actions {
    margin: 1rem;
    width: 25%;
    min-width: 25%;
}

.category-action-button {
    width: 25%;
    margin-left: 10px;

    &:last-child {
        margin-left: 5px;
    }
}

.category-arrow {
    margin: 1rem;
    width: 25%;
}

.category-tag-count {
    color: $dark-gray;
}

.tag-actions {
    margin-left: 1rem;
    margin-bottom: 1rem;
    display: flex;
}

.tag-action {
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0 1rem;
    margin-right: 3rem;

    &:focus {
        outline: 2px solid $edsights-blue;
    }
}

.action-icon {
    vertical-align: sub;
    height: 16px;
    width: 16px;
    margin-right: 5px;
}
</style>
