import AlertAlert from '../forked/vue-alert-alert@1.0.2/src';

// This file is currently just a pass-through to the above forked library.

// The plugin will be enabled globally and can be accessed from within Vue components:
// this.$Alert.alert({
//     type: 'error',
//     timeout: 10000,
//     message: 'Something went wrong'
// };

// Otherwise the exported `alert` function can be called directly in .js files
// where the Vue plugin is not accessible:
// import Alerts from '@/lib/alerts';
// Alerts.alert({
//     type: 'error',
//     timeout: 10000,
//     message: 'Something went wrong'
// };
export default { alert: AlertAlert.alert, plugin: AlertAlert.Plugin };
