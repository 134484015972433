<template>
    <div class="input-switch-container edsights-primevue-theme" :class="typeClass">
        <InputSwitch_
            :modelValue="modelValue"
            @update:modelValue="handleUpdate"
            :ariaLabel="ariaLabel ? ariaLabel : label"
            :ariaLabelledby="ariaLabelledby"
        />
        <label>{{ label }}</label>
    </div>
</template>

<script>
import InputSwitch_ from 'primevue/inputswitch';

export default {
    name: 'InputSwitch',
    components: { InputSwitch_ },
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: Boolean,
            required: true
        },
        label: {
            type: String,
            required: false
        },
        ariaLabel: {
            type: String,
            required: false
        },
        ariaLabelledby: {
            type: String,
            required: false
        },
        type: {
            type: String,
            default: 'DEFAULT',
            validator: value => {
                return ['DEFAULT'].includes(value);
            }
        }
    },
    computed: {
        typeClass() {
            const typeClassMap = {
                DEFAULT: 'default'
            };

            return typeClassMap[this.type] || '';
        }
    },
    methods: {
        handleUpdate(value) {
            this.$emit('update:modelValue', value);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

// All PrimeVue styling comes from the global "edsights-primevue-theme" class.
// Apply any PrimeVue overrides or style any other non-PrimeVue parts of this component below:
.input-switch-container {
    display: flex;
    align-items: center;

    label {
        margin-left: 10px;
    }

    &.default {
        :deep() {
            .p-inputswitch.p-highlight .p-inputswitch-slider {
                background: $green;
            }
        }
    }
}
</style>
