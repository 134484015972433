<template>
    <div class="flows">
        <div class="toolbar">
            <div class="school-filter">
                <SchoolDropDown @selected-school-id-updated="filterBySchool" />
            </div>
        </div>
        <div class="description">
            <h2>Flows</h2>
            <p>
                These are all the flows drafted and scheduled. You can filter by school, name,
                status, or label.
            </p>
        </div>

        <div class="box">
            <BoxContainer title="ALL FLOWS" hideSplitter>
                <LoadingSpinner v-if="loading" />
                <FlowsTable
                    v-if="!loading"
                    :columns="tableColumns"
                    :data="tableData"
                    :schoolFilter="schoolFilter"
                    :labels="labels"
                    @editFlow="editFlow"
                    @cancelFlow="openCancelFlowModal"
                    @createNew="goToCreateNew"
                    @flowDateUpdated="onFlowDateUpdated"
                    @flowScheduled="onFlowScheduled"
                />
            </BoxContainer>
        </div>
        <CancelFlowModal
            v-if="cancelFlowModalData.showModal"
            :flow="cancelFlowModalData.flow"
            @onClose="closeCancelFlowModal"
            @onSavedAsDraft="onSavedAsDraft"
            @onSavedAsCancelled="onSavedAsCancelled"
        />
    </div>
</template>

<script>
import BoxContainer from '@/components-deprecated/BoxContainer';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import SchoolDropDown from '@/components-deprecated/inputs/SchoolDropDown';
import FlowsTable from '@/views/staff/ChatbotFlowList/components/FlowsTable';
import { CHATBOT_FLOW_STATUS } from '@/consts/chatbot-flow';
import { getChatbotFlows } from '@/api/chatbot-flow';
import { getChatbotFlowLabels } from '@/api/chatbot-flow-labels';
import CancelFlowModal from '@/views/staff/ChatbotFlowList/components/CancelFlowModal';

export default {
    name: 'Flows',
    components: {
        FlowsTable,
        SchoolDropDown,
        BoxContainer,
        LoadingSpinner,
        CancelFlowModal
    },
    data() {
        return {
            flows: null,
            loading: false,
            selectedWorkflow: null,
            downloadingFlows: [],
            CHATBOT_FLOW_STATUS: CHATBOT_FLOW_STATUS,
            schoolFilter: '',
            labels: [],
            cancelFlowModalDataDefaults: {
                showModal: false,
                flow: null
            },
            cancelFlowModalData: { ...this.cancelFlowModalDataDefaults }
        };
    },
    computed: {
        tableColumns() {
            return [
                { dataKey: 'name', label: 'Template Name' },
                { dataKey: 'labels', label: 'Labels' },
                { dataKey: 'status', label: 'Status' },
                { dataKey: 'actions', label: 'Actions' }
            ];
        },
        tableData() {
            return this.flows.map(flow => ({
                id: flow.id,
                name: flow.templateRef.name,
                date: flow.date,
                labels: flow.labels,
                status: flow.status,
                historical: flow.historical,
                touchpointRef: flow.touchpointRef,
                schoolsRef: flow.schoolsRef
            }));
        }
    },
    async created() {
        try {
            this.loading = true;
            this.flows = await getChatbotFlows({
                statuses: [
                    CHATBOT_FLOW_STATUS.DRAFT.value,
                    CHATBOT_FLOW_STATUS.SCHEDULED.value,
                    CHATBOT_FLOW_STATUS.INITIATED.value
                ]
            });
            this.labels = await getChatbotFlowLabels();
            this.loading = false;
        } catch (error) {
            this.loading = false;
            this.$Alert.alert({
                type: 'error',
                message: 'There was a problem loading the flows. Please try again.'
            });
        }
    },
    methods: {
        goToCreateNew() {
            this.$router.push({
                name: 'ChatbotFlowBuilderCreate'
            });
        },
        editFlow(flowId) {
            this.$router.push({
                name: 'ChatbotFlowBuilderEdit',
                params: { flowId }
            });
        },
        openCancelFlowModal(flowId) {
            if (typeof flowId === 'string' && Array.isArray(this.flows)) {
                const flow = this.flows.find(f => f.id === flowId);
                if (typeof flow === 'object') {
                    this.cancelFlowModalData = {
                        ...this.cancelFlowModalDataDefaults,
                        showModal: true,
                        flow
                    };
                }
            }
        },
        async closeCancelFlowModal() {
            this.cancelFlowModalData = this.cancelFlowModalDataDefaults;
        },
        onSavedAsDraft(flowId) {
            if (typeof flowId === 'string' && Array.isArray(this.flows)) {
                // close modal and update component data to reflect change
                this.closeCancelFlowModal();
                this.flows = this.flows.map(flow => {
                    if (flow.id === flowId) {
                        return {
                            ...flow,
                            status: CHATBOT_FLOW_STATUS.DRAFT.value
                        };
                    }

                    return flow;
                });
            }
        },
        onSavedAsCancelled(flowId) {
            if (typeof flowId === 'string' && Array.isArray(this.flows)) {
                // close modal and update component data to reflect change
                this.closeCancelFlowModal();
                this.flows = this.flows.filter(flow => flow.id !== flowId);
            }
        },
        async filterBySchool(schoolId) {
            this.schoolFilter = schoolId;
        },
        onFlowDateUpdated(flow) {
            if (
                typeof flow === 'object' &&
                typeof flow.id === 'string' &&
                typeof flow.date === 'string'
            ) {
                const { id, date } = flow;
                this.flows = this.flows.map(flow => {
                    if (flow.id === id) {
                        return {
                            ...flow,
                            date
                        };
                    }

                    return flow;
                });
            }
        },
        onFlowScheduled(scheduledFlow) {
            if (typeof scheduledFlow === 'object' && typeof scheduledFlow.id === 'string') {
                this.flows = this.flows.map(flow => {
                    if (flow.id === scheduledFlow.id) {
                        return {
                            ...flow,
                            status: CHATBOT_FLOW_STATUS.SCHEDULED.value
                        };
                    }

                    return flow;
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.flows {
    min-height: 100vh;
    .description {
        margin-bottom: 10px;
    }
    .box {
        min-height: 500px;
    }
}
</style>
