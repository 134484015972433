import axios from 'axios';
import { v2ApiErrorHandler } from '@/api/lib/error-handler';
import * as Api from './index';

export const listFaqsv2BySchool = async schoolId => {
    try {
        const url = Api.getv2ApiBase(`/faq-v2/school/${schoolId}`);
        const response = await axios.get(url, Api.getOptions());
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error
        });
    }
};

export const archiveFaq = async faqId => {
    try {
        const url = Api.getv2ApiBase(`/faq-v2/${faqId}`);
        const response = await axios.delete(url, Api.getOptions());
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            rethrow: true
        });
    }
};

export const upsertFaqs = async ({ faqs, schoolId, errorHandlerOptions = {} }) => {
    try {
        const url = Api.getv2ApiBase(`/faq-v2/school/${schoolId}`);
        const response = await axios.post(url, faqs, Api.getOptions());
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            ...errorHandlerOptions
        });
    }
};

export const setEnabled = async ({ faqId, enabled, errorHandlerOptions = {} }) => {
    try {
        const url = Api.getv2ApiBase(`/faq-v2/${faqId}/set-enabled`);
        const response = await axios.put(url, { isEnabled: enabled }, Api.getOptions());
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            ...errorHandlerOptions
        });
    }
};

export const approveFaqs = async (schoolId, faqIds) => {
    try {
        const url = Api.getv2ApiBase(`/faq-v2/school/${schoolId}/approve-faqs`);
        const response = await axios.put(url, { faqIds }, Api.getOptions());
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            rethrow: true
        });
    }
};
