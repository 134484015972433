<template>
    <div class="invite-coadmins">
        <div class="wizard-step">
            <div class="wizard-step-header">
                <h2>Invite your co-admins to join.</h2>
                <p>
                    Co-admins are other people that may be interested in viewing which students are
                    at risk of dropping out. These may include advisors and deans. You'll have a
                    chance to set permission levels on the "Manage Co-Admins" screen after inviting
                    these users.
                </p>
            </div>

            <div class="wizard-step-main">
                <p>Paste co-admin emails</p>
                <LineNumberTextArea
                    class="invite-coadmins__textarea"
                    v-model="emailsPasted"
                    :full-width="true"
                    :key="'PastCoAdminEmails'"
                    :style="{
                        minHeight: '12rem'
                    }"
                >
                    <template v-slot:placeholder>
                        Email address...
                        <br />Email address... <br />Email address... <br />Email address...
                        <br />Email address... <br />Email address...
                        <br />
                    </template>
                </LineNumberTextArea>
                <p class="msg-email-per-row">
                    <em>Only one email per row (works best from a spreadsheet column).</em>
                </p>
            </div>

            <div class="wizard-step-footer">
                <Button styleType="off" @click="onBack()" class="back-button">Cancel</Button>
                <Button class="invite-button" @click="handleEmailList()">
                    <span v-if="!importingEmails">
                        Invite Co-Admins
                        <icon name="chevron-right" class="invite-button__arrow"></icon>
                    </span>
                    <span v-if="importingEmails">
                        <loading-spinner :color="'white'"></loading-spinner>
                    </span>
                </Button>
            </div>
        </div>
    </div>
</template>

<script>
import * as EmailValidator from 'email-validator';

import Admin from '@/services/admins';

import Icon from '@/components-deprecated/Icon';
import LineNumberTextArea from '@/components-deprecated/LineNumberTextArea';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import Button from '@/components-deprecated/Button';

export default {
    name: 'InviteCoAdmins',
    components: {
        Icon,
        LineNumberTextArea,
        LoadingSpinner,
        Button
    },
    data() {
        return {
            importingEmails: false,
            // Forms
            emailsPasted: ''
        };
    },
    methods: {
        onBack() {
            this.$router.push({ name: 'ManageCoAdmins' });
        },
        async handleEmailList() {
            if (this.importingEmails) {
                return;
            }

            let emails = this.emailsPasted;

            // START email validation

            // Vlidate is empty emails
            if (emails.length === 0) {
                this.$Alert.alert({
                    type: 'error',
                    message: "<h2>Emails can't be empty!</h2>",
                    timeout: 3000
                });
                return;
            }

            // Split emails string into a list
            emails = emails
                .split('\n')
                .map(email => email.trim())
                .filter(email => email !== '');

            // Check entered emails
            const incorrectEmails = emails.filter(
                email => EmailValidator.validate(email) === false
            );

            // If invalid email exists show message
            if (incorrectEmails.length !== 0) {
                this.$Alert.alert({
                    type: 'error',
                    message:
                        '<h2>Invalid emails</h2><h4>Please, correct these and try again:</h4>' +
                        incorrectEmails.map(email => `<p>${email}</p>`).join(''),
                    timeout: 15000
                });
                return;
            }
            // END email validation

            this.importingEmails = true;
            try {
                const result = await Admin.api.inviteCoadmins(emails);
                if (result.usersCreated.length !== 0) {
                    this.$Alert.alert({
                        type: 'success',
                        message: '<h2>Invitations sent</h2>',
                        timeout: 7000
                    });
                }
                if (result.usersExisting.length !== 0) {
                    this.$Alert.alert({
                        type: 'success',
                        message: `<h2>${result.usersExisting.length} admin(s) already exist.`,
                        timeout: 7000
                    });
                }
                this.$router.push({ name: 'ManageCoAdmins' });
            } catch (error) {
                console.log(error);
            } finally {
                this.importingEmails = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/wizards';

.invite-coadmins {
    flex: 1;

    &__textarea {
        padding-top: 17px;
        padding-left: 17px;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}

.invite-button {
    &__arrow {
        height: 1.5rem;
    }
}

.msg-email-per-row {
    margin: 0 0 0 1.5rem;
    color: $dark-gray;
}

em {
    font-size: 1rem;
}
</style>
