// External imports
import { createApp } from 'vue';
import axios from 'axios';
import PrimeVueConfig from 'primevue/config';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { vTooltip } from 'floating-vue';
import 'floating-vue/dist/style.css';
import { VueQueryPlugin } from '@tanstack/vue-query';

// Main app component and core feature imports
import App from './App';
import router from './router';
import store from './store';

// Other local imports
import Alerts from '@/lib/alerts';
import { registerGlobalChartJsPlugins } from '@/lib/charts.js-helpers';
import VModal from '@/forked/vue-js-modal/src';
import { initiatePosthogTracking } from './lib/analytics';

// Set global axios headers
if (process.env.VUE_APP_VERSION) {
    axios.defaults.headers.common['X-Edsights-Version'] = process.env.VUE_APP_VERSION;
}

// Create application
const app = createApp(App);

// Register chart.js plugins
registerGlobalChartJsPlugins();

// Register core features
app.use(store);
app.use(router);

// Register other features
app.directive('tooltip', vTooltip);
app.component('v-select', vSelect);
app.use(PrimeVueConfig);
app.use(VModal);
app.use(VueQueryPlugin);
app.use(Alerts.plugin);

// Analytics
initiatePosthogTracking({ router, store });

// Mount application
app.mount('#app');
