<template>
    <div class="header">
        <div class="header__question">{{ questions }}</div>
        <div class="header__answer">{{ answers }}</div>
        <div class="header__topic">{{ topics }}</div>
        <div class="header__subtopic">{{ subtopics }}</div>
        <div class="header__lastUpdated">{{ lastUpdated }}</div>
        <div class="header__status">{{ status }}</div>
        <div class="header__action">{{ actions }}</div>
    </div>
</template>

<script>
export default {
    name: 'Faqv2ListItemHeader',
    components: {},
    props: {
        questions: {
            type: String,
            default: 'Questions'
        },
        answers: {
            type: String,
            default: 'Answers'
        },
        topics: {
            type: String,
            default: 'Topics'
        },
        subtopics: {
            type: String,
            default: 'Sub-topics'
        },
        lastUpdated: {
            type: String,
            default: 'Last Updated'
        },
        status: {
            type: String,
            default: 'Status'
        },
        actions: {
            type: String,
            default: 'Actions'
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    watch: {},
    methods: {
        toggleSelected(value) {
            if (value) {
                this.$emit('select');
            } else {
                this.$emit('unselect');
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.header {
    align-items: center;
    justify-content: space-between;
    background-color: $white-blue;
    padding: 12px 0;
    display: table;
    top: 95px;
    position: sticky;
    z-index: 2;

    & > div {
        width: 150px;
        padding: 0 16px;
        word-wrap: break-word;
        display: table-cell;
        max-width: 150px;
    }
}
</style>
