<template>
    <div class="student-analysis-filter col">
        <div class="selected-filters-row row" v-if="shouldShowPins()">
            <div :key="tag.id" v-for="tag in filter.tags" class="selected-tag">
                <span class="selected-tag-name">{{ tag.name }}</span>
                <button
                    class="selected-tag-cancel"
                    @click.stop="onSelectedTagRemoved(tag)"
                    :aria-label="`Remove filter - ${tag.name}`"
                >
                    ✕
                </button>
            </div>
            <div v-if="filter.riskLevel != null" class="selected-tag">
                <span class="selected-tag-name">{{ filter.riskLevel.name }} Risk</span>
                <button
                    class="selected-tag-cancel"
                    @click.stop="onRiskLevelClick(null)"
                    :aria-label="`Remove filter - ${filter.riskLevel.name}`"
                >
                    ✕
                </button>
            </div>
            <div v-if="filter.riskDriver != null" class="selected-tag">
                <span class="selected-tag-name">{{ filter.riskDriver.name }} Risk Driver</span>
                <button
                    class="selected-tag-cancel"
                    @click.stop="onRiskDriverClick(null)"
                    :aria-label="`Remove filter - ${filter.riskDriver.name}`"
                >
                    ✕
                </button>
            </div>
            <div v-if="filter.phase != null" class="selected-tag">
                <span class="selected-tag-name"
                    >{{ phases.find(phase => phase.id == filter.phase).name }} Phase
                </span>
                <button
                    class="selected-tag-cancel"
                    @click.stop="onPhaseClick(null)"
                    aria-label="Remove filter - Phase"
                >
                    ✕
                </button>
            </div>
            <div v-if="filter.optedOutOfSms != null" class="selected-tag">
                <span class="selected-tag-name">
                    Opted In:{{
                        invalidPhoneOpts.find(opt => opt.value == filter.optedOutOfSms).key
                    }}
                </span>
                <button
                    class="selected-tag-cancel"
                    @click.stop="onOptInClick(null)"
                    aria-label="Remove filter - Opted In"
                >
                    ✕
                </button>
            </div>
            <div v-if="filter.invalidPhoneNumber !== null" class="selected-tag">
                <span class="selected-tag-name">
                    Valid Phone:{{
                        invalidPhoneOpts.find(opt => opt.value == filter.invalidPhoneNumber).key
                    }}</span
                >
                <button
                    class="selected-tag-cancel"
                    @click.stop="onValidPhoneClick(null)"
                    aria-label="Remove filter - Valid Phone"
                >
                    ✕
                </button>
            </div>
            <div v-if="showActionTakenFilter && filter.actionTaken !== null" class="selected-tag">
                <span class="selected-tag-name">
                    Action Taken:
                    {{ actionTakenOpts.find(opt => opt.value === filter.actionTaken).key }}</span
                >
                <button
                    class="selected-tag-cancel"
                    @click.stop="onActionTakenClick(null)"
                    aria-label="Remove filter - Action Taken"
                >
                    ✕
                </button>
            </div>
            <div v-if="filter.intervention != null" class="selected-tag">
                <span class="selected-tag-name">Intervention {{ filter.intervention.text }}</span>
                <button
                    class="selected-tag-cancel"
                    @click.stop="onInterventionClick(null)"
                    :aria-label="`Remove filter - ${filter.intervention.text}`"
                >
                    ✕
                </button>
            </div>
        </div>
        <div class="student-filters">
            <div class="student-filters__filters">
                <div class="filters-row row">
                    <div v-if="showStudentSearch" class="input-container">
                        <label v-if="isExperimentalAccessibilityEnabled"
                            >Search by Student Name</label
                        >
                        <div class="input-group search-bar">
                            <input
                                :placeholder="
                                    isExperimentalAccessibilityEnabled
                                        ? ''
                                        : 'Search a student name'
                                "
                                aria-label="Search by student name"
                                v-model="filter.search"
                                @input="executeFilter"
                            />
                            <svg class="icon-search" viewBox="0 0 50 50">
                                <use xlink:href="#icon-search" />
                            </svg>
                        </div>
                    </div>
                    <div v-if="showTagFilter" class="input-container" @click.stop>
                        <template v-if="isExperimentalAccessibilityEnabled">
                            <DropdownV2
                                :options="tagCategoriesList"
                                :selectedOptionValue="
                                    this.filter.tags[0] ? this.filter.tags[0].id : null
                                "
                                label="Filter by Tag"
                                optionLabelKey="combinedCategoryTagName"
                                optionValueKey="id"
                                @onChange="onTagSelected"
                            />
                        </template>
                        <template v-else>
                            <GroupedDropdown
                                label="Filter by Tag"
                                searchPlaceholder="Search a tag"
                                groupLabelKey="name"
                                groupValueKey="id"
                                groupOptionsKey="tagsRef"
                                optionLabelKey="name"
                                optionValueKey="id"
                                :groupedOptions="tagCategoriesList"
                                :selectedOptions="this.filter.tags"
                                :groupedOptionsLoading="isLoadingTags"
                                :searchLoading="this.tagCategories.refreshing"
                                :nextPageLoading="this.tagCategories.loadingNextPage"
                                :hasNextPage="tagCategories.pagination.next != null"
                                :isMultiSelect="!singleSelectTags"
                                enableExternalSearch
                                @search="onTagSearch"
                                @nextPage="onTagCategoriesNextPage"
                                @onChange="onTagSelected"
                            />
                        </template>
                    </div>
                    <div v-if="showRiskLevelFilter" class="input-container" @click.stop>
                        <template v-if="isExperimentalAccessibilityEnabled">
                            <DropdownV2
                                :options="riskLevels"
                                :selectedOptionValue="(filter.riskLevel || {}).id"
                                optionLabelKey="name"
                                optionValueKey="id"
                                label="Filter by Risk Level"
                                @onChange="onRiskLevelClick"
                            />
                        </template>
                        <template v-else>
                            <button
                                class="input-group cursor-pointer"
                                @click.stop="toggleDropdown('riskLevel')"
                            >
                                <label class="input-label faded"> Filter by Risk Level </label>
                                <div>
                                    <svg
                                        width="10px"
                                        height="10px"
                                        class="arrow-reversed"
                                        viewBox="0 0 5.9 17.51"
                                    >
                                        <use xlink:href="#icon-arrow-right-wide" />
                                    </svg>
                                </div>
                            </button>
                            <TransitionExpand>
                                <div
                                    class="transition-container"
                                    v-if="dropdowns.riskLevel"
                                    role="listbox"
                                >
                                    <button
                                        :key="riskLevel.id"
                                        v-for="riskLevel in riskLevels"
                                        class="dropdown-choice"
                                        @click.stop="onRiskLevelClick(riskLevel)"
                                        role="option"
                                    >
                                        {{ riskLevel.name }}
                                    </button>
                                </div>
                            </TransitionExpand>
                        </template>
                    </div>
                    <div v-if="showRiskDriverFilter" class="input-container" @click.stop>
                        <template v-if="isExperimentalAccessibilityEnabled">
                            <DropdownV2
                                :options="riskDrivers"
                                :selectedOptionValue="(filter.riskDriver || {}).id"
                                optionLabelKey="name"
                                optionValueKey="id"
                                label="Filter by Risk Driver"
                                @onChange="onRiskDriverClick"
                            />
                        </template>
                        <template v-else>
                            <button
                                class="input-group cursor-pointer"
                                @click.stop="toggleDropdown('riskDriver')"
                            >
                                <label class="input-label faded"> Filter by Risk Driver </label>
                                <div>
                                    <svg
                                        width="10px"
                                        height="10px"
                                        class="arrow-reversed"
                                        viewBox="0 0 5.9 17.51"
                                    >
                                        <use xlink:href="#icon-arrow-right-wide" />
                                    </svg>
                                </div>
                            </button>
                            <TransitionExpand>
                                <div
                                    class="transition-container"
                                    v-if="dropdowns.riskDriver"
                                    role="listbox"
                                >
                                    <button
                                        :key="riskDriver.id"
                                        v-for="riskDriver in riskDrivers"
                                        class="dropdown-choice"
                                        @click.stop="onRiskDriverClick(riskDriver)"
                                        role="option"
                                    >
                                        {{ riskDriver.name }}
                                    </button>
                                </div>
                            </TransitionExpand>
                        </template>
                    </div>
                </div>
                <div class="row" v-if="showMoreFilters">
                    <div v-if="showActionTakenFilter" class="input-container" @click.stop>
                        <template v-if="isExperimentalAccessibilityEnabled">
                            <DropdownV2
                                ref="actionTakenFilter"
                                :options="actionTakenOpts"
                                :selectedOptionValue="filter.actionTaken"
                                optionLabelKey="key"
                                optionValueKey="value"
                                label="Filter by Action Taken"
                                @onChange="option => onActionTakenClick(option.value)"
                            />
                        </template>
                        <template v-else>
                            <button
                                class="input-group cursor-pointer"
                                @click.stop="toggleDropdown('actionTaken')"
                                ref="actionTakenFilter"
                            >
                                <label class="input-label faded"> Filter by Action Taken </label>
                                <div>
                                    <svg
                                        width="10px"
                                        height="10px"
                                        class="arrow-reversed"
                                        viewBox="0 0 5.9 17.51"
                                    >
                                        <use xlink:href="#icon-arrow-right-wide" />
                                    </svg>
                                </div>
                            </button>
                            <TransitionExpand>
                                <div
                                    class="transition-container"
                                    v-if="dropdowns.actionTaken"
                                    role="listbox"
                                >
                                    <button
                                        :key="option.value"
                                        v-for="option in actionTakenOpts"
                                        class="dropdown-choice"
                                        @click.stop="onActionTakenClick(option.value)"
                                        role="option"
                                    >
                                        {{ option.key }}
                                    </button>
                                </div>
                            </TransitionExpand>
                        </template>
                    </div>
                    <div v-if="showPhaseFilter" class="input-container" @click.stop>
                        <template v-if="isExperimentalAccessibilityEnabled">
                            <DropdownV2
                                ref="phaseFilter"
                                :options="phases"
                                :selectedOptionValue="filter.phase"
                                optionLabelKey="name"
                                optionValueKey="id"
                                label="Filter by Phase"
                                @onChange="option => onPhaseClick(option.id)"
                            />
                        </template>
                        <template v-else>
                            <button
                                class="input-group cursor-pointer"
                                @click.stop="toggleDropdown('phase')"
                                ref="phaseFilter"
                            >
                                <label class="input-label faded">
                                    {{
                                        filter.phase
                                            ? phases.find(phase => phase.id == filter.phase).name
                                            : 'Filter by Phase'
                                    }}
                                </label>
                                <div>
                                    <svg
                                        width="10px"
                                        height="10px"
                                        class="arrow-reversed"
                                        viewBox="0 0 5.9 17.51"
                                    >
                                        <use xlink:href="#icon-arrow-right-wide" />
                                    </svg>
                                </div>
                            </button>
                            <TransitionExpand>
                                <div
                                    class="transition-container"
                                    v-if="dropdowns.phase"
                                    role="listbox"
                                >
                                    <button
                                        :key="`${index}-phase`"
                                        v-for="(phase, index) in phases"
                                        class="dropdown-choice"
                                        @click.stop="onPhaseClick(phase.id)"
                                        role="option"
                                    >
                                        {{ phase.name }}
                                    </button>
                                </div>
                            </TransitionExpand>
                        </template>
                    </div>
                    <div v-if="showNumberValidityFilter" class="input-container" @click.stop>
                        <template v-if="isExperimentalAccessibilityEnabled">
                            <DropdownV2
                                ref="phoneNumberValidityFilter"
                                :options="invalidPhoneOpts"
                                :selectedOptionValue="filter.invalidPhoneNumber"
                                optionLabelKey="key"
                                optionValueKey="value"
                                label="Filter by Number Validity"
                                @onChange="option => onValidPhoneClick(option.value)"
                            />
                        </template>
                        <template v-else>
                            <button
                                ref="phoneNumberValidityFilter"
                                class="input-group cursor-pointer"
                                @click.stop="toggleDropdown('validPhoneNumber')"
                            >
                                <label class="input-label faded">
                                    {{
                                        filter.invalidPhoneNumber
                                            ? invalidPhoneOpts.find(
                                                  opt => opt.value == filter.invalidPhoneNumber
                                              ).key
                                            : 'Filter by Number Validity'
                                    }}
                                </label>
                                <div>
                                    <svg
                                        width="10px"
                                        height="10px"
                                        class="arrow-reversed"
                                        viewBox="0 0 5.9 17.51"
                                    >
                                        <use xlink:href="#icon-arrow-right-wide" />
                                    </svg>
                                </div>
                            </button>
                            <TransitionExpand>
                                <div class="transition-container" v-if="dropdowns.validPhoneNumber">
                                    <button
                                        :key="`${index}-validphone`"
                                        v-for="(option, index) in invalidPhoneOpts"
                                        class="dropdown-choice"
                                        @click.stop="onValidPhoneClick(option.value)"
                                    >
                                        {{ option.key }}
                                    </button>
                                </div>
                            </TransitionExpand>
                        </template>
                    </div>
                    <div v-if="showOptInStatusFilter" class="input-container" @click.stop>
                        <template v-if="isExperimentalAccessibilityEnabled">
                            <DropdownV2
                                ref="optInFilter"
                                :options="invalidPhoneOpts"
                                :selectedOptionValue="filter.optedOutOfSms"
                                optionLabelKey="key"
                                optionValueKey="value"
                                label="Filter by Opt-in Status"
                                @onChange="option => onOptInClick(option.value)"
                            />
                        </template>
                        <template v-else>
                            <button
                                ref="optInFilter"
                                class="input-group cursor-pointer"
                                @click.stop="toggleDropdown('optIn')"
                            >
                                <label class="input-label faded">
                                    {{
                                        filter.optedOutOfSms
                                            ? invalidPhoneOpts.find(
                                                  opt => opt.value == filter.optedOutOfSms
                                              ).key
                                            : 'Filter by Opt-in Status'
                                    }}
                                </label>
                                <div>
                                    <svg
                                        width="10px"
                                        height="10px"
                                        class="arrow-reversed"
                                        viewBox="0 0 5.9 17.51"
                                    >
                                        <use xlink:href="#icon-arrow-right-wide" />
                                    </svg>
                                </div>
                            </button>
                            <TransitionExpand>
                                <div class="transition-container" v-if="dropdowns.optIn">
                                    <button
                                        :key="`${index}-optin`"
                                        v-for="(option, index) in invalidPhoneOpts"
                                        class="dropdown-choice"
                                        @click.stop="onOptInClick(option.value)"
                                    >
                                        {{ option.key }}
                                    </button>
                                </div>
                            </TransitionExpand>
                        </template>
                    </div>
                </div>
            </div>
            <div class="student-filters__actions">
                <Button
                    styleType="off"
                    v-if="showMoreFiltersButton"
                    v-bind:unbordered="true"
                    class="filter-button"
                    @click="toggleMoreFilters"
                    >{{ showMoreFilters ? 'Less Filters' : 'More Filters' }}</Button
                >
                <Button
                    styleType="off"
                    v-bind:unbordered="true"
                    class="filter-button"
                    @click="onFilterClear"
                    >Clear</Button
                >
            </div>
        </div>

        <div class="buttons-toolbar"></div>
    </div>
</template>

<script>
import { nextTick } from 'vue';

import TransitionExpand from '@/components-deprecated/TransitionExpand';
import CollectionManager from '@/services/collectionManager';
import TagCategories from '@/services/tagCategories';
import Button from '@/components-deprecated/Button';
import { debounce } from '@/services/utils';
import DropdownV2 from '@/components-deprecated/global/v2/DropdownV2.vue';
import { isExperimentalAccessibilityEnabled } from '@/lib/feature-flag';
import GroupedDropdown from '@/components-deprecated/global/GroupedDropdown';

const INVALID_PHONE_OPTS = [
    // opted in and valid phone endpoints are invalid_phone and opted_out of sms
    { key: 'Yes', value: false },
    { key: 'No', value: true }
];

const ACTION_TAKEN_OPTS = [
    { key: 'Yes', value: true },
    { key: 'No', value: false }
];

const PHASES = [
    { name: 'Adjustment', id: 'ADJUSTMENT' },
    { name: 'Growth', id: 'GROWTH' },
    { name: 'Preparing to leave', id: 'PREPARING_TO_LEAVE' },
    { name: 'Announcements', id: 'ANNOUNCEMENTS' },
    { name: 'No Phase', id: 'NO_PHASE' }
];

export default {
    name: 'StudentAnalysisFilter',
    components: {
        DropdownV2,
        TransitionExpand,
        Button,
        GroupedDropdown
    },
    data() {
        return {
            showMoreFilters: false,
            tagCategories: CollectionManager.create({
                ModelClass: TagCategories,
                filters: { has_students: true },
                v2: true,
                slim: true
            }),

            phases: PHASES,
            invalidPhoneOpts: INVALID_PHONE_OPTS,
            actionTakenOpts: ACTION_TAKEN_OPTS,
            isLoadingTags: false,
            filter: {
                searchText: '',
                tags: [],
                riskLevel: null,
                riskDriver: null,
                phase: null,
                optedOutOfSms: null,
                invalidPhoneNumber: null,
                intervention: null,
                actionTaken: null
            },
            dropdowns: {
                intervention: false,
                validPhoneNumber: false,
                optIn: false,
                riskLevel: false,
                riskDriver: false,
                phase: false,
                actionTaken: false
            },
            executeFilter: () => {},

            riskLevels: [
                { name: 'High', id: 'HIGH_RISK' },
                { name: 'Medium', id: 'MEDIUM_RISK' },
                { name: 'Low', id: 'LOW_RISK' }
            ],
            riskDrivers: [
                { name: 'Overall', id: 'overall' },
                { name: 'Academic', id: 'academic' },
                { name: 'Financial', id: 'financial' },
                { name: 'Engagement', id: 'engagement' },
                { name: 'Wellness', id: 'wellness' }
            ]
        };
    },
    props: {
        showStudentSearch: {
            type: Boolean,
            default: true
        },
        showTagFilter: {
            type: Boolean,
            default: true
        },
        showRiskLevelFilter: {
            type: Boolean,
            default: true
        },
        showRiskDriverFilter: {
            type: Boolean,
            default: true
        },
        showPhaseFilter: {
            type: Boolean,
            default: true
        },
        showNumberValidityFilter: {
            type: Boolean,
            default: true
        },
        showOptInStatusFilter: {
            type: Boolean,
            default: true
        },
        showActionTakenFilter: {
            type: Boolean,
            default: false
        },
        showMoreFiltersButton: {
            type: Boolean,
            default: true
        },
        // Set to true to allow 1 tag selection at a time, otherwise multiple can be selected.
        singleSelectTags: {
            type: Boolean,
            default: false
        },
        initialTags: {
            type: Array,
            default: () => [],
            validator: value => {
                if (!Array.isArray(value)) {
                    return false;
                }

                for (const item of value) {
                    if (typeof item !== 'object' ||
                        item === null ||
                        typeof item.id !== 'number' ||
                        typeof item.name !== 'string'
                    ) {
                        return false;
                    }
                }

                return true;
            }
        }
    },
    async created() {
        this.isLoadingTags = true;
        await this.tagCategories.refresh();
        if (this.initialTags.length > 0) {
            this.filter.tags = [...this.initialTags];
        }
        this.isLoadingTags = false;
        this.executeFilter = debounce(this.executeFilterUnbounced, 700);

        window.addEventListener('click', this.closeEvent);
    },
    computed: {
        tagCategoriesList() {
            if (typeof this.tagCategories === 'object' && Array.isArray(this.tagCategories.list)) {
                return this.isExperimentalAccessibilityEnabled
                    ? this.tagCategories.list
                          .reduce((acc, cat) => {
                              cat.tagsRef.forEach(tag => {
                                  acc.push({
                                      id: tag.id,
                                      name: tag.name,
                                      combinedCategoryTagName: `${cat.name}: ${tag.name}`
                                  });
                              });
                              return acc;
                          }, [])
                          .sort((a, b) =>
                              a.combinedCategoryTagName.localeCompare(b.combinedCategoryTagName)
                          )
                    : this.tagCategories.list;
            }

            return [];
        },
        isExperimentalAccessibilityEnabled() {
            if (this.$store.state.user.schoolRef) {
                return isExperimentalAccessibilityEnabled(this.$store.state.user.schoolRef.id);
            }

            return false;
        }
    },
    methods: {
        shouldShowPins() {
            return (
                this.filter.tags.length > 0 ||
                this.filter.riskLevel ||
                this.filter.riskDriver ||
                this.filter.phase ||
                this.filter.optedOutOfSms != null ||
                this.filter.invalidPhoneNumber != null ||
                this.filter.intervention ||
                this.filter.actionTaken != null
            );
        },
        toggleMoreFilters() {
            this.showMoreFilters = !this.showMoreFilters;

            // If the menu was closed, we're done here.
            if (this.showMoreFilters === false) {
                return;
            }

            // Focus the "next" filter, which may be different depending on which filters
            // are enabled:
            nextTick(() => {
                if (this.$refs.actionTakenFilter) {
                    this.$refs.actionTakenFilter.focus();
                    return;
                }

                if (this.$refs.phaseFilter) {
                    this.$refs.phaseFilter.focus();
                    return;
                }

                if (this.$refs.phoneNumberValidityFilter) {
                    this.$refs.phoneNumberValidityFilter.focus();
                    return;
                }

                if (this.$refs.optInFilter) {
                    this.$refs.optInFilter.focus();
                    return;
                }
            });
        },
        onTagCategoriesNextPage() {
            this.tagCategories.addNextPage();
        },
        async onTagSearch(searchText) {
            if (searchText) {
                this.tagCategories.filters.search = searchText;
            } else {
                this.tagCategories.filters.search = null;
            }

            await this.tagCategories.refresh();
        },
        onSelectedTagRemoved(tag) {
            this.filter.tags = this.filter.tags.filter(t => t.id !== tag.id);
            this.executeFilter();
        },
        onTagSelected(value) {
            // When accessibility is enabled, a simpler dropdown is used which only allows single
            // selection, and returns an object:
            if (this.isExperimentalAccessibilityEnabled) {
                if (this.filter.tags.includes(value)) {
                    this.filter.tags = [];
                } else {
                    this.filter.tags = [value];
                }
            } else {
                // When accessibility is not enabled, the dropdown will always return an array,
                // even in single-select mode:
                this.filter.tags = value;
            }

            this.executeFilter();
        },
        onRiskLevelClick(riskLevel) {
            this.filter.riskLevel = riskLevel;
            this.dropdowns.riskLevel = false;
            this.executeFilter();
        },
        onRiskDriverClick(riskDriver) {
            this.filter.riskDriver = riskDriver;
            this.dropdowns.riskDriver = false;
            this.executeFilter();
        },
        onPhaseClick(phase) {
            this.filter.phase = phase;
            this.dropdowns.phase = false;
            this.executeFilter();
        },
        onOptInClick(option) {
            this.filter.optedOutOfSms = option;
            this.dropdowns.optIn = false;
            this.executeFilter();
        },
        onValidPhoneClick(option) {
            this.filter.invalidPhoneNumber = option;
            this.dropdowns.validPhoneNumber = false;
            this.executeFilter();
        },
        onActionTakenClick(option) {
            this.filter.actionTaken = option;
            this.dropdowns.actionTaken = false;
            this.executeFilter();
        },
        onInterventionClick(option) {
            this.filter.intervention = option;
            this.dropdowns.intervention = false;
            this.executeFilter();
        },
        onFilterClear() {
            this.filter = {
                searchText: '',
                tags: [],
                riskLevel: null,
                riskDriver: null,
                phase: null,
                optedOutOfSms: null,
                invalidPhoneNumber: null,
                intervention: null
            };
            this.executeFilter();
        },
        executeFilterUnbounced() {
            let filter = {};
            if (this.filter.search) {
                filter.search = this.filter.search;
            }

            if (this.filter.tags && this.filter.tags.length > 0) {
                filter.tagIds = this.filter.tags.map(t => t.id).join();
            }

            if (this.filter.riskLevel) {
                let riskDriverId = null;
                if (this.filter.riskDriver) {
                    riskDriverId = this.filter.riskDriver.id;
                } else {
                    riskDriverId = 'overall';
                }
                let riskDriverFilterName = `${riskDriverId}RiskLevel`;
                filter[riskDriverFilterName] = this.filter.riskLevel.id;
            } else if (this.filter.riskDriver) {
                let riskDriverId = this.filter.riskDriver.id;
                let riskDriverFilterName = `${riskDriverId}RiskLevel`;
                filter[riskDriverFilterName] = 'MEDIUM_RISK,HIGH_RISK';
            }
            filter.phase = this.filter.phase;
            filter.optedOutOfSms = this.filter.optedOutOfSms;
            filter.invalidPhoneNumber = this.filter.invalidPhoneNumber;
            filter.intervention = this.filter.intervention ? this.filter.intervention.id : null;

            if (this.showActionTakenFilter) {
                filter.actionTaken = this.filter.actionTaken;
            }

            this.$emit('filterChanged', filter);
        },
        closeEvent() {
            this.closeDropdowns();
        },
        toggleDropdown(dropdownName) {
            this.dropdowns[dropdownName] = !this.dropdowns[dropdownName];
            if (this.dropdowns[dropdownName]) {
                this.closeDropdowns(dropdownName);
            }
        },
        closeDropdowns(activeDropdownName) {
            Object.keys(this.dropdowns)
                .filter(d => !activeDropdownName || d !== activeDropdownName)
                .forEach(d => (this.dropdowns[d] = false));
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.student-filters {
    display: flex;
    align-items: center;

    &__filters {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        // flex: 1 0 auto;

        > * {
            margin: 1rem 0;
        }
    }

    &__actions {
        display: flex;
        // margin-left: auto;

        .button {
            margin: 0 0.5rem 0 0;
            min-width: 50px;
            padding: 0 0.5rem;
            color: $dark-gray;

            &:hover {
                color: $primary-brand-color;
            }
        }

        align-items: flex-end;

        > * {
            flex: 0 1 auto;
        }
    }
}

.col {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1rem 0;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 0 auto;
    min-height: 35px;
    flex-wrap: wrap;
}

.selected-tag {
    position: relative;
    border: 1px solid $dodger-blue;
    border-radius: 5px;
    padding: 0.2rem 0.75rem;
    margin-right: 1rem;
    width: 15rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.selected-tag-name {
    margin-right: 1rem;
}

.selected-tag-cancel {
    all: unset;
    cursor: pointer;
    position: absolute;
    top: 0.5rem;
    right: 0.25rem;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    border-radius: 4px;

    &:focus {
        outline: 2px solid $edsights-blue;
    }
}

.input-container {
    width: 230px;
    position: relative;
    align-items: center;
    margin-right: 2rem;
}

.filter-button {
    margin-right: 1rem;
    color: $accessible-gray;

    &:last-child {
        margin-right: 4rem;
    }
}

.input-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: $white-blue;
    padding: 0.25rem 1rem;
    border-radius: 5px;
    border: 0;
    min-height: 32px;
    width: 100%;
    color: $base-font-color;
    font-size: 12px;
    letter-spacing: 1px;

    &:focus {
        outline: 2px solid $edsights-blue;
    }

    > input {
        width: 100%;
        border: none;
        outline: none;
        background-color: $white-blue;
        padding: 0.25rem 3rem 0.25rem 1rem;
        border-radius: 5px;
        font: inherit;
        color: $input-gray;

        &:focus {
            outline: 2px solid $edsights-blue;
        }
    }

    .icon-search {
        position: absolute;
        right: 12px;
    }

    &.search-bar {
        padding: 0;
        background: transparent;
        border-radius: 5px;
        position: relative;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.input-label {
    cursor: pointer;
    background-color: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 20rem;
}

.faded {
    color: $input-gray;
}

.clear-input-button {
    margin-right: 1rem;
}

.bordered {
    border: 1px solid $silver;
}

.arrow {
    transform: rotate(-90deg);

    &-reversed {
        transform: rotate(90deg);
    }
}

.icon-search {
    height: 15px;
    width: 15px;
    margin-right: 0;
}

.transition-container {
    position: absolute;
    z-index: 1000;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
    background-color: $white;
    left: 0;
    right: 0;
    max-height: 400px;
}

.dropdown {
    margin: 1rem;
}

.dropdown-choice {
    cursor: pointer;
    padding: 0.75rem 0.75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    color: $base-font-color;
    font-size: 12px;
    letter-spacing: 1px;
    border: 0;
    background: #fff;
    text-align: left;
    margin-bottom: 2px;
    line-height: 26px;

    &:hover {
        background-color: $white-blue;
    }

    &:focus {
        outline: 2px solid $edsights-blue;
    }
}

.intervention-template-container {
    max-height: 300px;
    overflow-y: scroll;
    margin: 2rem 0rem;
}

.button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > :last-child {
        margin-left: 1rem;
    }
}

.buttons-toolbar {
    display: flex;
    justify-content: center;
}
</style>
