<template>
    <div class="resources">
        <BoxContainer
            class="resources__header"
            title="Resources"
            subtitle="Getting Started with EdSights"
            :showMoreTo="showMoreTo"
            fixedHeader
        >
            <OverviewListItem
                v-for="resource in resources"
                class="resources__item"
                :key="resource.id"
                :title="resource.title"
                :resourceSubsections="resource.subSections"
                :resourceSectionDescription="resource.description"
                :selected="selectedItem.subSections == resource.subSections"
                :hideBorder="resources.indexOf(resource) === resources.length - 1"
                @clickDescription="onSelectResource(resource)"
                @clickResourceSubsection="onSelectSubsection"
                :selectedSubSectionName="selectedSubSectionName"
            />
        </BoxContainer>
    </div>
</template>

<script>
import Header from '@/components-deprecated/overview/Header';
import OverviewListItem from '@/components-deprecated/overview/OverviewListItem';
import BoxContainer from '@/components-deprecated/BoxContainer';

export default {
    name: 'Resource',

    components: {
        Header,
        OverviewListItem,
        BoxContainer
    },

    props: {
        resources: {
            type: Array,
            required: true
        },
        showMoreTo: {
            type: Object,
            default: null
        },
        selectedItem: {
            type: Object,
            default: null
        },
        selectedSubsection: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            selectedSubSectionName: this.selectedItem.subSection,
            notionHelpLink: process.env.VUE_APP_NOTION_HELP_DOC_LINK,
            notionToolkitLink: process.env.VUE_APP_NOTION_COMMUNICATION_TOOLKIT_LINK
        };
    },
    computed: {},
    methods: {
        onSelectResource(resource) {
            this.$emit('resource', resource);
        },
        onSelectSubsection(subsection, description) {
            switch(subsection.subSection) {
                case 'EdSights Help Website':
                    window.open(this.notionHelpLink, '_blank');
                    break;
                case 'EdSights Communication Toolkit':
                    window.open(this.notionToolkitLink, '_blank');
                    break;
                default:
                this.selectedSubSectionName = subsection.subSection;
                this.$emit('selectedSubsection', subsection, description);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.resources {
    position: relative;

    &__header {
        margin-bottom: 25px;
    }

    &__item {
        & :deep(.item__description) {
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }
    .splitter {
        position: absolute;
        left: -20px;
        right: -20px;
        height: 2px;
        background-color: $border-color;
    }
}
</style>
