<template>
    <div>
        <div class="messaging_track">
            <div class="messaging_track__name">
                {{ messagingTrack.displayName }}
            </div>
            <div class="messaging_track__count">{{ messagingTrack.studentCount }}</div>
            <div class="messaging_track__linked">
                <div :key="tag.id" v-for="tag in messagingTrack.fullTags" class="selected-tag">
                    <span class="selected-tag-name">{{ tag.name }}</span>
                </div>
            </div>
            <div class="messaging_track__button">
                <div class="add-create">
                    <Button styleType="transparent" v-if="userIsStaff" @click="displayModal">Add/Edit</Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components-deprecated/global/Button';

export default {
    name: 'MessagingTracksItem',
    components: { Button },
    props: {
        messagingTrack: {
            required: true
        }
    },
    methods: {
        displayModal() {
            this.$emit('handleClick', this.messagingTrack.id);
        }
    },
    computed: {
        userIsStaff() {
            return this.$store.state.user.isStaff;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.selected-tag {
    position: relative;
    border: 1px solid $dodger-blue;
    border-radius: 5px;
    padding: 0.2rem 0.75rem;
    margin-right: 1rem;
    width: 15rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.selected-tag-name {
    margin-right: 1rem;
}

.messaging_track {
    position: relative;
    display: flex;
    align-items: start;
    justify-content: space-between;
    padding: 10px 20px;

    & > * {
        width: 15%;
    }

    &__name {
        width: 30%;
    }

    &__linked {
        width: 55%;
        display: flex;
        flex-direction: row;
        overflow-y: scroll;
    }

    &__button {
        width: 5%;
    }

    &__count {
        width: 10%;
    }

    .v--modal-overlay {
        width: 100%;
    }
}
</style>
