import axios from 'axios';
import { apiErrorHandler } from '../services/api';
import { v2ApiErrorHandler } from '@/api/lib/error-handler';
import * as Api from './index';

// find points of contact
export const findPointsOfContact = () => {
    const url = Api.getv2ApiBase('/user/points-of-contact');

    return axios
        .get(url, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(
            apiErrorHandler({
                apiName: 'Find School By Id error'
            })
        );
};

export const listUsersByEmail = ({ params }) => {
    const url = Api.getv2ApiBase(`/user/list-by-email`);
    const options = Api.getOptions();

    return axios
        .get(url, { ...options, params })
        .then(response => {
            return response.data;
        })
        .catch(error => v2ApiErrorHandler({ error }));
};

// retire a user
export const retireUser = userId => {
    const url = Api.getv2ApiBase(`/user/${userId}/retire`);
    const payload = { userId };

    return axios
        .patch(url, payload, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(error => v2ApiErrorHandler({ error }));
};
