<template>
    <div id="app">
        <Navigation
            v-if="showUserNavigation"
            ref="UserNavigation"
            :show-schools="showSchools"
            @toggled-school="showSchoolSelector = !showSchoolSelector"
            @untoggled-school="untoggleSchool"
        />

        <!--    todo: this can probably be removed - showSchoolSelector never gets set-->
        <transition v-if="showSchoolSelector && isStaff" name="schools">
            <div class="school-options">
                <div class="search-schools">
                    <input
                        class="search-schools__input"
                        type="text"
                        @input="searchSchoolName($event.target.value)"
                        placeholder="Search for schools"
                    />
                    <svg class="icon-search" viewBox="0 0 50 50">
                        <use xlink:href="#icon-search" />
                    </svg>
                </div>
                <ul class="school-list">
                    <li
                        @click="toggleSchool(school)"
                        class="school-list__item"
                        v-for="school in schools.list"
                        :key="school.id"
                        v-show="school.name.toLowerCase().includes(searchText)"
                    >
                        {{ school.name }}
                    </li>
                    <li v-if="schools.pagination.next != null">
                        <Paginator
                            v-if="schools.pagination.next != null"
                            :nextPage="schools.pagination.next != null"
                            @next-page="schools.addNextPage()"
                        />
                    </li>
                </ul>
            </div>
        </transition>

        <alert-alert />
        <Spinner v-if="globalLoading" />
        <main
            id="main"
            tabindex="-1"
            class="router-wrapper"
            :class="{
                'router-wrapper--hide-border': hideBorder,
                'router-wrapper--no-nav': !showUserNavigation,
                'staff-school-view-enabled': staffSchoolViewEnabled
            }"
        >
            <div
                class="router"
                :class="{
                    'router-logged-out': !showUserNavigation
                }"
                ref="Router"
            >
                <BackgroundOperation
                    v-if="showUserNavigation"
                    v-for="op in backgroundOperations"
                    :key="op.id"
                    :backgroundOperation="op"
                />
                <!-- Binding a key to the full path will remount a view if the detail endpoint changes-->
                <router-view
                    :key="$route.fullPath"
                    @scrollToTop="scrollToTop"
                    @shouldShowSchools="shouldShowSchools"
                />
            </div>
        </main>
        <ModalWelcome />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Navigation from '@/components-deprecated/Navigation';
import Spinner from '@/components-deprecated/Spinner';
import ModalWelcome from '@/views/authentication/ModalWelcome';
import Auth from '@/services/auth';
import Staff from '@/services/staff';
import School from '@/services/schools';
import Paginator from '@/components-deprecated/Paginator';
import CollectionManager from '@/services/collectionManager';
import { debounce } from '@/services/utils';
import BackgroundOperation from '@/components-deprecated/BackgroundOperation';
import { useIdle } from '@vueuse/core';
import 'primeicons/primeicons.css';
const ONE_MIN = 1000 * 60;
const IDLE_LOGOUT_TIME = ONE_MIN * 90;
const IDLE_WARNING_TIME = IDLE_LOGOUT_TIME - ONE_MIN;

export default {
    name: 'App',
    components: {
        Navigation,
        Spinner,
        ModalWelcome,
        Paginator,
        BackgroundOperation
    },
    setup() {
        const { lastActive } = useIdle(0);
        return {
            lastActive
        };
    },
    data() {
        return {
            schools: CollectionManager.create({
                ModelClass: School
            }),
            currentRouteName: this.$router.currentRoute.name,
            removeRouterListener: null,
            showSchoolSelector: false,
            showSchools: true,
            searchText: '',
            logoutWarned: false
        };
    },
    computed: {
        isStaff() {
            return this.$store.state.user.isStaff;
        },
        showUserNavigation() {
            const isHideNavbarPage = [
                'Login',
                'Registration',
                'ForgotPassword',
                'ForgotPasswordSuccess',
                'PasswordResetConfirm',
                'SurveyIntro',
                'SurveyContent',
                'SurveyEnd',
                'MagicLink',
                'StaffEnterSchoolPage'
            ].includes(this.currentRouteName);
            return !isHideNavbarPage && this.userIsLoggedIn;
        },
        hideBorder() {
            return [
                'Login',
                'Registration',
                'ForgotPassword',
                'ForgotPasswordSuccess',
                'PasswordResetConfirm',
                'SurveyIntro',
                'SurveyContent',
                'SurveyEnd',
                'StaffEnterSchoolPage'
            ].includes(this.currentRouteName);
        },
        hideTransition() {
            return [
                'Login',
                'Registration',
                'ForgotPassword',
                'ForgotPasswordSuccess',
                'PasswordResetConfirm',
                'InviteCoAdmins'
            ].includes(this.currentRouteName);
        },
        ...mapGetters(['userIsLoggedIn']),
        ...mapState(['globalLoading', 'backgroundOperations', 'staffSchoolViewEnabled'])
    },
    async created() {
        // listen to route changes
        this.removeRouterListener = this.$router.afterEach(to => {
            // Set page title:
            document.title = to.meta && to.meta.title ? `EdSights - ${to.meta.title}` : 'EdSights';
            // Track route name, so we can hide/show navigation:
            this.currentRouteName = to.name;
        });
        // Check Logged-in state of current user
        if (this.userIsLoggedIn) {
            this.$store.dispatch('refreshProfile');
        }

        if (this.isStaff) {
            await this.schools.refresh();
        }
        this.searchSchoolName = debounce(this.searchSchoolName, 700);
        this.setLastActive = debounce(this.setLastActive, 1000);
    },
    mounted() {
        this.watchActivity();
    },
    beforeUnmount() {
        this.removeRouterListener();
        clearInterval(this.activityWatcherId);
    },
    methods: {
        scrollToTop() {
            this.$refs.Router.scrollTo(0, 0);
        },
        untoggleSchool() {
            this.showSchoolSelector = false;
        },
        shouldShowSchools(shouldShow) {
            this.showSchools = this.isStaff && shouldShow;
        },
        async toggleSchool(school) {
            // used to toggle schools for staff
            const res = await Staff.api.update(this.$store.state.user.id, school);
            if (res) {
                this.$store.dispatch('refreshProfile');
            }
        },
        async searchSchoolName(val) {
            const value = val;
            this.searchText = value;
            if (this.isStaff) {
                // always reset the page to 1 if a filter changes
                this.schools.filters.page = 1;
                this.schools.filters.search = this.searchText;
                this.schools.refresh();
            }
        },
        setLastActive(timestamp) {
            localStorage.setItem('lastActive', timestamp);
        },
        watchActivity() {
            this.activityWatcherId = setInterval(() => {
                if (!Auth.isLoggedIn()) {
                    return;
                }
                const lastActiveTimestamp = localStorage.getItem('lastActive');
                const currentTimestamp = new Date().getTime();
                if (
                    currentTimestamp - lastActiveTimestamp >= IDLE_WARNING_TIME &&
                    !this.logoutWarned
                ) {
                    this.logoutWarned = true;
                    this.$Alert.alert({
                        type: 'warning',
                        message:
                            'You will be logged out in 1 minute due to inactivity. Any mouse or keyboard activity keeps you signed in.',
                        timeout: 20000
                    });
                }
                if (currentTimestamp - lastActiveTimestamp >= IDLE_LOGOUT_TIME) {
                    Auth.logout();
                    this.$router.push({ name: 'Login' });
                    this.$Alert.alert({
                        type: 'error',
                        message: 'You have been logged out due to inactivity.',
                        timeout: 20000
                    });
                }
            }, 15000);
        }
    },
    watch: {
        async isStaff(curr) {
            if (curr === true) {
                await this.schools.refresh();
            }
        },
        lastActive(lastActive) {
            if (lastActive) {
                this.logoutWarned = false;
                this.setLastActive(lastActive);
            }
        }
    }
};
</script>

<style lang="scss">
@import './styles/variables';
@import './styles/base';

#app {
    // Important for full-height flexbox layout!
    height: 100%;
    overflow-y: hidden;
}

.router-wrapper {
    background-color: #f5f6fa;
    height: calc(100vh - #{$navigation-height});
    overflow-y: hidden;
    margin-top: $navigation-height;

    &.staff-school-view-enabled {
        $content-height: calc(100vh - #{$staff-total-navigation-height});
        height: $content-height;
        margin-top: $staff-total-navigation-height;

        .router {
            height: $content-height;
        }
    }

    &--no-nav {
        margin-top: 0;
    }

    &--hide-border {
        padding: 0;
        height: 100vh;

        .router {
            height: 100vh;
        }
    }
}

.router {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-x: hidden;
    // Important for full-height flexbox layout!
    margin-left: 0;
    background-color: $white;
    height: calc(100vh - #{$navigation-height});
    box-shadow: 0 0 10px #00000021;

    &-logged-out {
        padding-top: 0;
    }
}

@media screen and (min-width: 728px) {
    .router {
        &.router-logged-out {
            flex-direction: row;
            margin-left: 0;
        }
    }
}

.school-options {
    position: absolute;
    background-color: $white;
    z-index: 12;
    height: 250px;
    left: 73rem;
    top: 4rem;
    transform: translateX(-50%);
    @include box-shadow;
}

.school-option {
    margin: 0rem 1rem;
    display: flex;
}

.schools {
    margin-bottom: 10px;
    overflow: hidden;
}

.schools-enter-active {
    transition: all 0.25s linear;
}

.schools-leave-active {
    transition: all 0.25s linear;
}

.schools-enter,
.schools-leave-to {
    max-height: 0;
    opacity: 0;
    // margin-bottom: 0;
}

.schools-enter-to,
.schools-leave {
    max-height: 50px;
    opacity: 1;
    margin-bottom: 10px;
}

.icon-search {
    width: 20px;
    height: 20px;
    margin-right: 15px;
    fill: $main-gray;
}

.search-schools {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $secondary-gray;
    border-radius: 4px;
    margin: 10px 20px;

    &:focus {
        border-color: $edsights-blue;
    }

    &__input {
        border: 0 !important;
        padding: 10px 15px !important;
    }
}

.school-list {
    list-style: none;
    height: 178px;
    overflow-y: auto;

    &__item {
        padding: 5px 20px;

        &:hover {
            background-color: #eef9ff;
            cursor: pointer;
        }
    }
}
</style>
