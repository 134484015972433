<template>
    <div v-if="shouldShow" class="intervention-progress-container">
        <template v-if="allActionsTaken">
            All Actions Taken <Emoji emoji="partyPopper" />
        </template>

        <template v-else>
            <div class="stats">
                <label>Actions Taken</label>
                <span>
                    {{ interventionTemplate.actionsTaken }} /
                    {{ interventionTemplate.totalActions }} ({{
                        interventionTemplate.percentActionsTaken
                    }}%)
                </span>
            </div>
            <div>
                <ProgressBar :percent="interventionTemplate.percentActionsTaken" />
            </div>
        </template>
    </div>
</template>

<script>
import ProgressBar from '@/components-deprecated/global/ProgressBar';
import Emoji from '@/components-deprecated/global/Emoji';

export default {
    name: 'InterventionProgress',
    components: {
        Emoji,
        ProgressBar
    },
    props: {
        interventionTemplate: {
            validator: function(value) {
                if (value) {
                    return (
                        typeof value === 'object' &&
                        typeof value.type === 'string' &&
                        typeof value.actionsTaken === 'number' &&
                        typeof value.totalActions === 'number' &&
                        typeof value.percentActionsTaken === 'number'
                    );
                }
            }
        }
    },
    computed: {
        shouldShow() {
            if (this.interventionTemplate) {
                return (
                    this.interventionTemplate.type === 'ADMIN' &&
                    this.interventionTemplate.actionsTaken > 0
                );
            }

            return false;
        },
        allActionsTaken() {
            if (this.interventionTemplate) {
                return this.interventionTemplate.percentActionsTaken === 100;
            }

            return false;
        }
    }
};
</script>

<style lang="scss" scoped>
.intervention-progress-container {
    font-size: 0.9rem;
    label {
        margin-right: 1rem;
    }
    .stats {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
</style>
