import axios from 'axios';
import * as Api from './index';
import { v2ApiErrorHandler } from '@/api/lib/error-handler';

export const createChatbotTemplateLabelMappings = ({ mappings, errorHandlerOptions = {} }) => {
    const url = Api.getv2ApiBase(`/chatbot-template-label-mapping`);
    const options = Api.getOptions();

    return Promise.all(
        (mappings || []).map(mapping =>
            axios
                .post(url, mapping, options)
                .then(response => {
                    return response.data;
                })
                .catch(error =>
                    v2ApiErrorHandler({
                        error,
                        rethrow: true,
                        enableAlert: false
                    })
                )
        )
    ).catch(error =>
        v2ApiErrorHandler({
            error,
            overrideMessage:
                'There was a problem saving the Chatbot Template Label(s). Please try again.',
            ...errorHandlerOptions
        })
    );
};
