import api from './api';
import options from './options';
import Student from '../students';
import { objectToCamelCase, objectToSnakeCase } from '../utils';

export default class Alert {
    static api = api.create(Alert);
    static options = options;

    constructor(answer) {
        const {
            id = null,
            datetimeCreated = null,
            text = '',
            alertStatus = null,
            lastEditedUserRef = null,
            respondentRef = null,
            triggerCategory = '',
            chatbotMessagePromptRef = null,
            chatbotMessageOutgoingRef = null,
            lastEditedUserEmail = null,
        } = answer;
        Object.assign(this, {
            id,
            datetimeCreated: new Date(datetimeCreated),
            text,
            alertStatus,
            lastEditedUserRef,
            lastEditedUserEmail,
            respondentRef: Student.fromAPI(respondentRef),
            triggerCategory,
            chatbotMessagePromptRef,
            chatbotMessageOutgoingRef
        });
    }

    static create(opts) {
        return new Alert(opts);
    }

    static fromAPI(json) {
        return new Alert(objectToCamelCase(json));
    }

    static toAPI(alert, fields = [], excludeFields = []) {
        // By default, send the whole object
        let data = {};

        // If it's a partial update, get only the fields specified
        if (fields.length > 0) {
            fields.forEach(field => {
                data[field] = alert[field];
            });
        } else {
            data = alert;
        }

        //
        excludeFields = [...Alert.readOnlyFields, ...excludeFields];
        excludeFields.forEach(item => {
            delete data[item];
        });

        return objectToSnakeCase(data);
    }
    static checkIsInstance(obj, name) {
        if (!(obj instanceof Alert)) {
            throw Error(`${name} must be an instance of the Alert class.`);
        }
    }
}
