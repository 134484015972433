<template>
    <modal
        class="reset-password-modal"
        name="modal-add-edit-faq-v2"
        height="auto"
        :clickToClose="false"
        :stack="false"
        :scrollable="false"
        @before-open="beforeOpen"
        tabIndex="-1"
    >
        <div class="container">
            <h2>{{ faq.id ? 'Edit Question' : 'Add Question' }}</h2>

            <div class="input">
                <label for="faq-question" class="header">Question<span> *</span></label>
                <ElasticTextArea
                    v-model.trim="faq.questionText"
                    placeholder="Question"
                    style="height: 10rem; padding: 1rem 2rem"
                    bordered
                    id="faq-question"
                />
            </div>

            <div class="input">
                <label for="faq-answer" class="header">Answer<span> *</span></label>
                <ElasticTextArea
                    v-model.trim="faq.answerText"
                    placeholder="Answer"
                    style="height: 10rem; padding: 1rem 2rem"
                    bordered
                    id="faq-answer"
                />
                <label>
                    Best practice: Keep answers around 200 characters and use supporting URL’s.
                    Answers should not be empty or contain only URLs.
                </label>
            </div>

            <div class="input">
                <label for="faq-topic" class="header">Topic<span> *</span></label>
                <Dropdown
                    :options="topics"
                    @option-clicked="topicChangeHandler"
                    :label="selectedTopicLabel"
                    :useStyledLabel="false"
                    :activeOptions="[faq.topicId]"
                />
            </div>

            <div class="input">
                <label for="faq-subtopic" class="header">Sub-topic<span> *</span></label>
                <Dropdown
                    :options="subtopics"
                    @option-clicked="subtopicChangeHandler"
                    :label="selectedSubtopicLabel"
                    :useStyledLabel="false"
                    :activeOptions="[faq.subtopicId]"
                />
            </div>

            <div class="input" style="display: flex; flex-direction: column">
                <label for="faq-subtopic" class="header">Status<span> *</span></label>
                <div style="display: flex; flex-direction: row">
                    <InputSwitch
                        v-model="faq.isEnabled"
                        :label="faq.isEnabled ? 'Approved' : 'Pending'"
                        ariaLabel="Toggle FAQ as approved or pending"
                    />
                </div>
            </div>

            <div class="footer">
                <Button type="button" class="cancel-btn" @click="onClose"> Cancel </Button>
                <loading-spinner-button
                    class="save-btn"
                    :text="getSaveButtonText"
                    :loading="loading"
                    @click="upsertHandler()"
                />
            </div>
        </div>
    </modal>
</template>

<script>
import Lodash from 'lodash';
import LoadingSpinnerButton from '@/components-deprecated/LoadingSpinnerButton.vue';
import Dropdown from '@/components-deprecated/global/Dropdown';
import * as FAQv2Api from '@/api/faqs-v2';
import ElasticTextArea from '@/components-deprecated/ElasticTextArea';
import InputSwitch from '@/components-deprecated/global/v2/InputSwitch';

const getInitialData = () => ({
    formErrors: null,
    loading: false,
    faq: {}
});

export default {
    name: 'ModalAddEditFaqV2',
    components: { InputSwitch, LoadingSpinnerButton, ElasticTextArea, Dropdown },
    data() {
        return getInitialData();
    },
    props: {
        faqTopics: {
            type: Array,
            required: true
        }
    },
    computed: {
        getSaveButtonText() {
            if (this.faq.id) {
                return 'Update question';
            }

            return 'Add question';
        },
        topics() {
            return this.faqTopics.map(t => {
                return {
                    label: t.name,
                    value: t.id
                };
            });
        },
        subtopics: {
            get: function() {
                const topic = this.faqTopics.find(f => f.id === this.faq.topicId);
                if (topic) {
                    return topic.subtopics.map(s => {
                        return {
                            label: s.name,
                            value: s.id
                        };
                    });
                }
                return [];
            },
            set: function(value) {
                this.value = value;
            }
        },
        selectedTopicLabel() {
            const selectedTopic = this.faqTopics.find(t => t.id === this.faq.topicId);
            if (selectedTopic) {
                return selectedTopic.name;
            }
            return null;
        },
        selectedSubtopicLabel() {
            const selectedTopic = this.faqTopics.find(t => t.id === this.faq.topicId);
            if (selectedTopic) {
                const selectedSubtopic = selectedTopic.subtopics.find(
                    s => s.id === this.faq.subtopicId
                );
                return selectedSubtopic ? selectedSubtopic.name : null;
            }
            return null;
        }
    },
    methods: {
        async beforeOpen(event) {
            Object.assign(this.$data, getInitialData());
            if (event.params.faq) {
                this.faq = Lodash.clone(event.params.faq);
                const topic = this.faqTopics.find(f => f.id === this.faq.topicId);
                if (topic) {
                    this.subtopics = topic.subtopics;
                }
            } else {
                this.faq = {};
            }
        },
        isValidFaq() {
            const validationWarnings = [];
            if (!this.faq.questionText || this.faq.questionText === '') {
                validationWarnings.push('Question is required!');
            }
            if (!this.faq.answerText || this.faq.answerText === '') {
                validationWarnings.push('Answer is required!');
            }
            if (!this.faq.topicId) {
                validationWarnings.push('Topic is required!');
            }
            if (!this.faq.subtopicId) {
                validationWarnings.push('Subtopic is required!');
            }

            if (validationWarnings.length > 0) {
                this.$Alert.alert({
                    type: 'warning',
                    message: validationWarnings.join('<br />'),
                    timeout: 10000
                });
                return false;
            }

            return true;
        },
        async upsertHandler() {
            try {
                if (this.isValidFaq()) {
                    this.loading = true;

                    await FAQv2Api.upsertFaqs({
                        faqs: [this.faq],
                        schoolId: this.$store.state.user.schoolRef.id,
                        errorHandlerOptions: {
                            rethrow: true
                        }
                    });

                    this.loading = false;
                    this.$emit('refresh-faqs', this.faq);
                    this.$modal.hide('modal-add-edit-faq-v2');
                    this.$Alert.alert({
                        type: 'success',
                        message: 'Faq saved!',
                        timeout: 2500
                    });
                }
            } catch (error) {
                this.loading = false;
            }
        },
        onClose() {
            this.$modal.hide('modal-add-edit-faq-v2');
        },
        topicChangeHandler(topicId) {
            if (topicId) {
                const topic = this.faqTopics.find(f => f.id === topicId);

                if (topic) {
                    this.faq = Object.assign({}, this.faq, {
                        topicId,
                        topic: topic.name,
                        subtopicId: null
                    });

                    this.subtopics = topic.subtopics;
                }
            }
        },
        subtopicChangeHandler(subtopicId) {
            const subtopic = this.subtopics.find(subtopic => subtopic.value === subtopicId);
            if (subtopicId) {
                this.faq = Object.assign({}, this.faq, {
                    subtopicId,
                    subtopic: subtopic.label
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/forms';
@import '../../styles/mixins/alerts';
@import '@/styles/variables';
@import '@/styles/mixins/buttons';

.container {
    padding: 1.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    max-height: 90vh;
    overflow: auto;

    .input {
        margin-bottom: 1rem;
        width: 30rem;
    }
}

.footer {
    display: flex;
    justify-content: flex-end;
    margin: 2rem 0 1rem 0;
}

.save-btn {
    @include base-button();
    width: 160px;
}

.cancel-btn {
    @include secondary-button();
    margin-right: 1rem;
}
</style>
