<template>
    <div class="container">
        <ButtonBack />
        <div class="box">
            <h1 class="h2">Link Tags to Messaging Tracks</h1>
            <p>Map the messaging tracks below to the relevant tags at this institution</p>
            <List v-if="isLoading === false">
                <template v-slot:list-header>
                    <MessagingTracksHeader />
                </template>
                <template v-slot:list-items>
                    <div id="messaging-track-list">
                        <MessagingTracksItem
                            v-for="messagingTrack in messagingTracks"
                            :key="messagingTrack.id"
                            :messagingTrack="messagingTrack"
                            @handleClick="handleMessagingTrackEdit"
                        />
                    </div>
                </template>
            </List>
            <LoadingSpinner v-if="isLoading" />
        </div>
        <MessagingTracksEditTagsModal
            v-if="modalData.showModal"
            @onClose="onClose"
            @save="save"
            :tags="modalData.messagingTrack.fullTags"
        />
    </div>
</template>
<script>
import Lodash from 'lodash';
import List from '@/components-deprecated/tables/List';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import ButtonBack from '@/components-deprecated/ButtonBack';
import MessagingTracksHeader from '@/components-deprecated/tables/MessagingTracksHeader';
import MessagingTracksItem from '@/components-deprecated/tables/MessagingTracksItem';
import MessagingTracksEditTagsModal from '@/components-deprecated/tables/MessagingTracksEditTagsModal';
import { getStudentFilterStudentCount } from '@/api/student-filter';
import { listMessagingTracks, replaceMessagingTrackTags } from '@/api/messaging-tracks';

const defaultModalData = {
    showModal: false,
    messagingTrack: null
};

export default {
    name: 'MessagingTracks',
    components: {
        List,
        MessagingTracksHeader,
        MessagingTracksItem,
        MessagingTracksEditTagsModal,
        ButtonBack,
        LoadingSpinner
    },

    data() {
        return {
            messagingTracks: [],
            isLoading: true,
            modalData: defaultModalData
        };
    },
    async created() {
        this.loadMessagingTracks();
        this.isLoading = false;
    },
    methods: {
        onClose() {
            this.modalData = defaultModalData;
        },
        save(tagIds) {
            if (!Array.isArray(tagIds)) {
                return;
            }
            this.selectionChange({
                messagingTrackId: this.modalData.messagingTrack.id,
                tagIds
            });
        },
        handleMessagingTrackEdit(messagingTrackId) {
            const messagingTrack = Lodash.find(
                this.messagingTracks,
                m => m.id === messagingTrackId
            );

            if (!messagingTrack) {
                return;
            }

            this.modalData = {
                showModal: true,
                messagingTrack
            };
        },
        async updateStudentCount(messagingTrackId) {
            if (typeof messagingTrackId !== 'string') {
                return;
            }
            const messagingTrack = Lodash.find(
                this.messagingTracks,
                m => m.id === messagingTrackId
            );

            if (!Array.isArray(messagingTrack.tags) || !messagingTrack.tags.length) {
                messagingTrack.studentCount = 0;
                return;
            }
            messagingTrack.studentCount = await getStudentFilterStudentCount({
                schoolId: messagingTrack.schoolId,
                tagIds: null,
                messagingTrackId: messagingTrack.id,
                firstTimeEngager: null,
                phases: null,
                riskLevels: null
            });
        },
        async selectionChange({ messagingTrackId, tagIds }) {
            const newTags = await replaceMessagingTrackTags(messagingTrackId, tagIds);

            if (!Array.isArray(newTags)) {
                return;
            }
            const messagingTrack = Lodash.find(
                this.messagingTracks,
                m => m.id === messagingTrackId
            );
            messagingTrack.fullTags = [...newTags];
            messagingTrack.tags = newTags.map(t => t.id);
            await this.updateStudentCount(messagingTrackId);
            this.modalData = defaultModalData;
        },
        async loadMessagingTracks() {
            if (this.$store.state.user.schoolRef) {
                const messagingTracks = await listMessagingTracks(
                    this.$store.state.user.schoolRef.id
                );
                this.messagingTracks = messagingTracks;
            }
        },
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/mixins/buttons';

.container {
    height: 100%;
}
.box {
    height: 100%;
}
</style>
