<template>
    <div class="dashboard-container">
        <div class="school-container">
            <div class="school-dropdown">
                <v-select
                    :options="schools"
                    v-model="selectedSchool"
                    label="name"
                    @update:modelValue="schoolSelectionHandler"
                    placeholder="Search for a school..."
                    :clearable="false"
                />
            </div>
            <div class="flag-table-header">
                <div class="flag-header-large">Feature Flag</div>
                <div class="flag-header-small">Enabled</div>
            </div>
            <div v-if="showLoadingSpinner">
                <LoadingSpinner />
            </div>
            <div class="flag-list-container" v-else>
                <div
                    v-for="flag in schoolFeatureFlagList"
                    :key="flag.feature_flag_name"
                    class="flag"
                >
                    <div class="flag-details">
                        <div class="flag-detail-cell-large">
                            {{ snakeToTitle(flag.feature_flag_name) }}
                        </div>
                        <div class="flag-detail-cell-small">
                            <Checkbox
                                :modelValue="flag.enabled"
                                @update:modelValue="handleCheckboxClick(flag)"
                            />
                        </div>
                    </div>
                </div>
                <Modal
                    v-if="isModalVisible"
                    title="Confirm Changes"
                    size="medium"
                    primaryButtonTitle="Confirm"
                    @secondary-button-clicked="isModalVisible = false"
                    @primary-button-clicked="confirmToggle"
                >
                    <div>
                        {{ message }}
                    </div>
                </Modal>
            </div>
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select';
import { startCase, toLower } from 'lodash';
import * as SchoolApi from '@/api/school';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import Checkbox from '@/components-deprecated/global/Checkbox';
import Modal from '@/components-deprecated/global/Modal';

export default {
    name: 'ToggleFeatureFlags',
    components: {
        LoadingSpinner,
        Checkbox,
        vSelect,
        Modal
    },
    data() {
        return {
            showLoadingSpinner: false,
            schoolId: '',
            schools: [],
            schoolFeatureFlagList: [],
            selectedSchool: '',
            selectedFlag: '',
            isModalVisible: false
        };
    },
    created() {
        this.loadSchools();
    },
    methods: {
        snakeToTitle(snakeCaseString) {
            return startCase(toLower(snakeCaseString));
        },
        async schoolSelectionHandler(value) {
            this.selectedSchool = value;
            if (!value) {
                this.schoolFeatureFlagList = [];
            } else {
                this.schoolFeatureFlagList = await SchoolApi.listFeatureFlagsBySchoolId(
                    this.selectedSchool.id
                );
            }
        },
        handleCheckboxClick(flag) {
            this.selectedFlag = flag;
            this.message = `Are you sure you want to toggle ${this.snakeToTitle(
                flag.feature_flag_name
            )} for ${this.selectedSchool.name}?`;
            this.isModalVisible = true;
        },
        async confirmToggle() {
            const response = await SchoolApi.toggleFeatureFlagForSchool({
                payload: {
                    schoolId: this.selectedSchool.id,
                    featureFlagName: this.selectedFlag.feature_flag_name,
                    enabled: !this.selectedFlag.enabled
                }
            });
            if (response) {
                this.$Alert.alert({
                    type: 'success',
                    message: `${this.snakeToTitle(this.selectedFlag.feature_flag_name)} ${
                        response.enabled ? 'enabled' : 'disabled'
                    }.`,
                    timeout: 3000
                });
                this.selectedFlag.enabled = response.enabled;
                this.isModalVisible = false;
            }
        },
        async loadSchools() {
            this.schools = (
                await SchoolApi.listSchools({ queryParams: { pageSize: 500, isActive: true } })
            ).results;
        }
    }
};
</script>

<style lang="scss" scoped>
.dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.school-dropdown {
    margin-top: 2rem;
    width: 30rem;
}
.flag-list-container {
    width: 100%;
}
.flag {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.school-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 45%;
}
.flag-table-header {
    display: flex;
    padding: 10px 20px;
    background-color: #f4f7f9;
    justify-content: space-evenly;
    font-weight: bold;
    width: 100%;
    margin-top: 1rem;
}
.flag:hover {
    background-color: #f4f7f9;
}
.flag-header-small {
    flex: 0.1;
}
.flag-header-large {
    flex: 0.7;
}
.flag-details {
    display: flex;
    justify-content: space-evenly;
    padding: 10px 20px;
    width: 100%;
}
.flag-detail-cell-small {
    flex: 0.1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.flag-detail-cell-large {
    flex: 0.7;
}
</style>
