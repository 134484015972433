<template>
    <div class="student-voice-score-by-institution-type-modal-container">
        <Modal
            title="Compare Your SVS to Peer Institutions"
            size="large"
            :showPrimaryButton="false"
            secondaryButtonTitle="Close"
            @secondary-button-clicked="close"
        >
            <div class="student-voice-score-by-institution-type-modal-content-container">
                <p>
                    Customize the nationwide benchmark to create a peer group that is relevant to
                    your institution. Note this data is real-time and updates as students nationwide
                    respond.
                </p>

                <Chips
                    class="chips"
                    :chips="selectedInstitutionTypeChips"
                    @chip-clicked="removeSelectedInstitutionType"
                    showCloseIcon
                />
                <GroupedDropdown
                    label="IPEDS Attributes"
                    :groupedOptions="renderedInstitutionTypeCategories"
                    :groupedOptionsLoading="institutionTypeCategoriesLoading"
                    :selectedOptions="selectedInstitutionTypes"
                    groupLabelKey="name"
                    groupValueKey="id"
                    groupOptionsKey="institutionTypes"
                    optionLabelKey="name"
                    optionValueKey="id"
                    @onChange="setSelectedInstitutionTypes"
                    enableInternalGroupLabelSearch
                />
                <LoadingSpinner v-if="svsBySelectedInstitutionTypesLoading" />
                <ScoreProgress
                    v-if="showScoreProgress"
                    differenceLabelPrefix="Your SVS is"
                    differenceLabelSuffix="your selected peer group"
                    comparedScoreLabel="Your SVS"
                    invertDifference
                    :score="svsBySelectedInstitutionTypes"
                    :comparedScore="score"
                />
                <p v-if="showNotFoundMessage">
                    There currently isn't enough data on the selected peer group to generate a
                    benchmark. Try again by selecting a broader peer group.
                </p>
            </div>
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components-deprecated/global/Modal.vue';
import GroupedDropdown from '@/components-deprecated/global/GroupedDropdown';
import * as InstitutionTypeCategoryApi from '@/api/institution-type-category';
import Chips from '@/components-deprecated/global/Chips';
import { getStudentVoiceScoreByInstitutionTypeIds } from '@/api/student-voice-score';
import ScoreProgress from '@/views/StudentVoiceScorePage/components/components/ScoreProgress/ScoreProgress.vue';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner.vue';
import { roundSvsScore } from '@/views/StudentVoiceScorePage/helpers/svs';

export default {
    name: 'StudentVoiceScoreByInstitutionTypeModal',
    components: { LoadingSpinner, ScoreProgress, Modal, GroupedDropdown, Chips },
    props: {
        score: {
            type: Number,
            required: false
        }
    },
    data() {
        return {
            institutionTypeCategoriesLoading: false,
            institutionTypeCategories: [],
            selectedInstitutionTypes: [],
            svsBySelectedInstitutionTypes: null,
            svsBySelectedInstitutionTypesLoading: false
        };
    },
    async created() {
        this.institutionTypeCategoriesLoading = true;
        this.institutionTypeCategories = await InstitutionTypeCategoryApi.listInstitutionTypeCategories(
            {
                errorHandlerOptions: {
                    rethrow: false,
                    enableAlert: true
                }
            }
        );
        this.institutionTypeCategoriesLoading = false;
    },
    methods: {
        close() {
            this.$emit('close');
        },
        setSelectedInstitutionTypes(types) {
            this.selectedInstitutionTypes = types;
        },
        removeSelectedInstitutionType(type) {
            this.selectedInstitutionTypes = this.selectedInstitutionTypes.filter(
                selected => selected.id !== type.value
            );
        },
        async loadSvsBySelectedInstitutionTypes() {
            this.svsBySelectedInstitutionTypesLoading = true;
            this.svsBySelectedInstitutionTypes = null;
            const svs = await getStudentVoiceScoreByInstitutionTypeIds({
                schoolId: this.$store.state.user.schoolRef.id,
                institutionTypeIds: this.selectedInstitutionTypes.map(type => type.id),
                errorHandlerOptions: {
                    rethrow: false,
                    enableAlert: true
                }
            });

            if (typeof svs === 'number') {
                this.svsBySelectedInstitutionTypes = roundSvsScore(svs);
            }

            this.svsBySelectedInstitutionTypesLoading = false;
        }
    },
    computed: {
        renderedInstitutionTypeCategories() {
            if (Array.isArray(this.institutionTypeCategories)) {
                return [...this.institutionTypeCategories]
                    .sort((a, b) => a.displayOrder - b.displayOrder)
                    .map(cat => ({
                        ...cat,
                        institutionTypes: cat.institutionTypes.sort(
                            (a, b) => a.displayOrder - b.displayOrder
                        )
                    }));
            }

            return [];
        },
        selectedInstitutionTypeChips() {
            return this.selectedInstitutionTypes.map(selected => ({
                label: selected.name,
                value: selected.id
            }));
        },
        showScoreProgress() {
            return typeof this.svsBySelectedInstitutionTypes === 'number';
        },
        showNotFoundMessage() {
            return (
                !this.svsBySelectedInstitutionTypesLoading &&
                this.selectedInstitutionTypes.length &&
                typeof this.svsBySelectedInstitutionTypes != 'number'
            );
        }
    },
    watch: {
        async selectedInstitutionTypes(selected) {
            if (selected.length) {
                await this.loadSvsBySelectedInstitutionTypes();
            } else {
                this.svsBySelectedInstitutionTypes = null;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.student-voice-score-by-institution-type-modal-container {
    .student-voice-score-by-institution-type-modal-content-container {
        min-height: 330px;

        & > * {
            margin-bottom: 12px;
        }

        .chips {
            :deep(.chip) {
                border-radius: 5px;
            }
        }
    }
}
</style>
