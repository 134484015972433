<template>
    <div class="tag">
        <div class="tag-checkbox-container">
            <input
                v-if="selectedTags"
                class="tag-checkbox"
                type="checkbox"
                :checked="selectedTags.tags && selectedTags.tags.includes(value)"
                @change="onSelect($event)"
            />
        </div>
        <div class="tag-name">
            {{ name }}
        </div>
        <div class="tag-count">
            {{ studentCount }}
        </div>
        <div class="tag-actions">
            <Button class="tag-btn" style-type="off" @click="onClickViewStudents"
                >View/Edit Students</Button
            >
            <Button
                v-if="!this.value.isPublic"
                class="tag-btn"
                style-type="off"
                @click="onClickEdit"
                >Rename Tag</Button
            >
        </div>
        <div class="tag-date">Last Updated {{ lastUpdatedDate }}</div>
    </div>
</template>

<script>
import Button from '@/components-deprecated/Button';
import momentTz from 'moment-timezone';

export default {
    name: 'TagListItem',

    components: {
        Button
    },

    props: {
        name: {
            type: String,
            default: ''
        },
        studentCount: {
            type: [String, Number],
            default: 0
        },
        value: {
            type: Object,
            default: null
        },
        selectedTags: {
            type: Object,
            default: null
        },
        tagCategoryId: {
            type: String,
            default: null
        }
    },

    computed: {
        lastUpdatedDate() {
            let date = this.value.studentsLastModified;
            return momentTz
                .utc(date)
                .tz('America/New_York')
                .format('MMM D, YYYY');
        }
    },

    methods: {
        onClickViewStudents() {
            this.$emit('viewStudents', this.value);
        },
        onClickEdit() {
            this.$emit('edit', this.value);
        },
        onClickDelete() {
            this.$emit('delete', this.value);
        },
        onSelect(event) {
            this.$emit('selected', this.tagCategoryId, this.value, event.target.checked);
        }
    }
};
</script>

<style lang="scss" scoped>
.tag {
    display: flex;
    align-items: center;
    padding: 10px 45px;

    &-checkbox-container {
        width: 2%;
    }

    &-checkbox {
        vertical-align: sub;
    }

    &-name {
        flex: 0.5;
    }

    &-count {
        flex: 0.5;
    }

    &-actions {
        flex: 0.75;
    }

    &-date {
        flex: 0.4;
    }

    &-btn {
        width: 140px;
        margin-left: 10px;

        &:last-child {
            margin-left: 5px;
        }

        &:only-child {
            margin-right: 145px;
        }
    }
}
</style>
