<template>
    <div class="templates">
        <div class="description">
            <h2>Templates</h2>
            <p>
                These are your reusable templates for chatbot flows.
            </p>
        </div>

        <div class="box">
            <BoxContainer title="TEMPLATES" hideSplitter>
                <LoadingSpinner v-if="loading" />
                <TemplatesTable
                    v-else
                    ref="templatesTable"
                    :pagedTemplates="pagedTemplates"
                    :labels="labels"
                    @editTemplate="editTemplate"
                    @deleteTemplates="deleteTemplates"
                    @createNew="goToCreateNew"
                    @nextPage="loadNextPage"
                    @filtersUpdated="filterTemplates"
                    @addLabelToTemplates="openLabelsModal"
                    @assignTemplatesToSchool="openSchoolsModal"
                    :nextPageLoading="nextPageLoading"
                />
            </BoxContainer>
        </div>
        <TemplatesLabelModal
            v-if="labelModalData.showModal"
            @onClose="closeLabelsModal"
            @onSave="createTemplateMappings"
            :saving="labelModalData.saving"
        />
        <SchoolsModal
            v-if="schoolsModalData.showModal"
            @onClose="closeSchoolsModal"
            @onSave="createFlowsFromTemplates"
            :saving="schoolsModalData.saving"
        />
    </div>
</template>

<script>
import { keyBy } from 'lodash';
import BoxContainer from '@/components-deprecated/BoxContainer';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import TemplatesTable from '@/views/staff/ChatbotFlowList/components/TemplatesTable';
import { getChatbotTemplates, updateChatbotFlowTemplates } from '@/api/chatbot-template';
import { getChatbotFlowLabels } from '@/api/chatbot-flow-labels';
import TemplatesLabelModal from '@/views/staff/ChatbotFlowList/components/TemplatesLabelModal';
import { createChatbotTemplateLabelMappings } from '@/api/chatbot-template-label-mapping';
import SchoolsModal from '@/views/staff/ChatbotFlowList/components/SchoolsModal';
import { createFlowsFromTemplateIds } from '@/api/chatbot-flow';
import { isUIFeatureFlagEnabled } from '@/lib/feature-flag';
import { createChatbotFlowLabelMappings } from '@/api/chatbot-flow-label-mappings';

export default {
    name: 'Templates',
    components: {
        TemplatesLabelModal,
        SchoolsModal,
        TemplatesTable,
        BoxContainer,
        LoadingSpinner
    },
    data() {
        return {
            pagedTemplates: null,
            loading: false,
            nextPageLoading: false,
            labels: [],
            labelModalData: {
                showModal: false,
                templateIds: [],
                saving: false
            },
            schoolsModalData: {
                showModal: false,
                templateIds: [],
                saving: false
            },
            enableSeparateFlowsFromTemplates: isUIFeatureFlagEnabled(
                'VUE_APP_FEATURE_FLAG_SEPARATE_FLOWS_FROM_TEMPLATES'
            )
        };
    },
    async created() {
        this.loading = true;

        const pagedTemplates = await this.getReusableChatbotTemplates();
        if (typeof pagedTemplates === 'object' && Array.isArray(pagedTemplates.results)) {
            this.pagedTemplates = pagedTemplates;
        }

        const labels = await getChatbotFlowLabels();
        if (Array.isArray(labels)) {
            this.labels = labels;
        }

        this.loading = false;
    },
    methods: {
        goToCreateNew() {
            if (this.enableSeparateFlowsFromTemplates) {
                this.$router.push({
                    name: 'ChatbotFlowBuilderCreateTemplate'
                });
            } else {
                this.$router.push({
                    name: 'ChatbotFlowBuilderCreate'
                });
            }
        },
        editTemplate(templateId) {
            if (this.enableSeparateFlowsFromTemplates) {
                const template = this.pagedTemplates.results.find(t => t.id === templateId);
                if (template && template.chatbotFlowId) {
                    this.$router.push({
                        name: 'ChatbotFlowBuilderEdit',
                        params: { flowId: template.chatbotFlowId }
                    });
                }
            } else {
                const template = this.pagedTemplates.results.find(t => t.id === templateId);
                if (template && template.chatbotFlowId) {
                    this.$router.push({
                        name: 'ChatbotFlowBuilderCreateFromTemplate',
                        params: { templateFlowId: template.chatbotFlowId }
                    });
                }
            }
        },
        async deleteTemplates(templateIds) {
            if (!Array.isArray(templateIds)) {
                return;
            }

            const response = confirm(
                'Are you sure you want to mark the template(s) as non-reusable?'
            );
            if (!response) {
                return;
            }

            // "delete" actually means marking the template as reusable: false.
            // update the templates
            const updated = await updateChatbotFlowTemplates({
                chatbotFlowTemplates: templateIds.map(id => ({ id, data: { reusable: false } }))
            });

            // remove non-reusable templates from the list of templates
            if (Array.isArray(updated)) {
                this.pagedTemplates = {
                    ...this.pagedTemplates,
                    results: this.pagedTemplates.results.filter(
                        template =>
                            !updated.find(updatedTemplate => updatedTemplate.id === template.id)
                    )
                };
                const updatedCount = updated.length;
                this.$Alert.alert({
                    type: 'success',
                    timeout: 7000,
                    message: `${updatedCount} ${
                        updatedCount > 1 ? 'templates' : 'template'
                    } deleted.`
                });
            }
        },
        async loadNextPage() {
            this.nextPageLoading = true;
            const nextPagedTemplates = await getChatbotTemplates({
                paginationUrl: this.pagedTemplates.next
            });

            if (
                typeof nextPagedTemplates === 'object' &&
                Array.isArray(nextPagedTemplates.results)
            ) {
                this.pagedTemplates = {
                    ...nextPagedTemplates,
                    results: [...this.pagedTemplates.results, ...nextPagedTemplates.results]
                };
            }
            this.nextPageLoading = false;
        },
        async filterTemplates(filters) {
            const nextPagedTemplates = await this.getReusableChatbotTemplates(filters);

            if (
                typeof nextPagedTemplates === 'object' &&
                Array.isArray(nextPagedTemplates.results)
            ) {
                this.pagedTemplates = nextPagedTemplates;
            }
        },
        getReusableChatbotTemplates(filters) {
            let params = {};
            if (typeof filters === 'object') {
                params = filters;
            }
            return getChatbotTemplates({
                queryParams: {
                    reusable: true,
                    page: 1,
                    ...params
                }
            });
        },
        openLabelsModal(templateIds) {
            this.labelModalData = {
                showModal: true,
                saving: false,
                templateIds
            };
        },
        closeLabelsModal() {
            this.labelModalData = {
                showModal: false
            };
        },
        openSchoolsModal(templateIds) {
            this.schoolsModalData = {
                showModal: true,
                saving: false,
                templateIds
            };
        },
        closeSchoolsModal() {
            this.schoolsModalData = {
                showModal: false
            };
        },
        async createTemplateMappings(labelId) {
            if (
                this.enableSeparateFlowsFromTemplates &&
                typeof labelId === 'string' &&
                typeof this.labelModalData === 'object' &&
                Array.isArray(this.labelModalData.templateIds) &&
                typeof this.pagedTemplates === 'object' &&
                Array.isArray(this.pagedTemplates.results)
            ) {
                this.labelModalData.saving = true;
                const templatesMappedById = keyBy(this.pagedTemplates.results, 'id');
                // create mappings for templates that don't have the label already mapped
                const newLabelMappings = this.labelModalData.templateIds.reduce(
                    (acc, templateId) => {
                        const template = templatesMappedById[templateId];
                        if (
                            typeof template === 'object' &&
                            Array.isArray(template.labels) &&
                            !template.labels.find(l => l.id === labelId)
                        ) {
                            return [
                                ...acc,
                                {
                                    chatbotFlowId: template.chatbotFlowId,
                                    labelId
                                }
                            ];
                        }
                        return acc;
                    },
                    []
                );

                if (newLabelMappings.length) {
                    const createdMappings = await createChatbotFlowLabelMappings({
                        mappings: newLabelMappings
                    });
                    if (Array.isArray(createdMappings)) {
                        // update this.labels and this.pagedTemplates to reflect changes
                        const labels = await getChatbotFlowLabels();
                        if (Array.isArray(labels)) {
                            this.labels = labels;

                            const mappedLabel = this.labels.find(l => l.id === labelId);
                            if (
                                typeof mappedLabel === 'object' &&
                                typeof mappedLabel.id === 'string' &&
                                typeof mappedLabel.text === 'string' &&
                                typeof this.pagedTemplates === 'object' &&
                                Array.isArray(this.pagedTemplates.results)
                            ) {
                                const newMappingsMappedByChatbotFlowId = keyBy(
                                    newLabelMappings,
                                    'chatbotFlowId'
                                );
                                this.pagedTemplates = {
                                    ...this.pagedTemplates,
                                    results: this.pagedTemplates.results.map(template => {
                                        if (
                                            newMappingsMappedByChatbotFlowId[template.chatbotFlowId]
                                        ) {
                                            return {
                                                ...template,
                                                labels: [...template.labels, mappedLabel]
                                            };
                                        }

                                        return template;
                                    })
                                };
                            }
                        }

                        this.closeLabelsModal();
                    }
                    this.labelModalData.saving = false;
                } else {
                    this.closeLabelsModal();
                }
            }

            if (
                !this.enableSeparateFlowsFromTemplates &&
                typeof labelId === 'string' &&
                typeof this.labelModalData === 'object' &&
                Array.isArray(this.labelModalData.templateIds) &&
                typeof this.pagedTemplates === 'object' &&
                Array.isArray(this.pagedTemplates.results)
            ) {
                this.labelModalData.saving = true;
                const templatesMappedById = keyBy(this.pagedTemplates.results, 'id');
                // create mappings for templates that don't have the label already mapped
                const newTemplateLabelMappings = this.labelModalData.templateIds.reduce(
                    (acc, templateId) => {
                        const template = templatesMappedById[templateId];
                        if (
                            typeof template === 'object' &&
                            Array.isArray(template.labels) &&
                            !template.labels.find(l => l.id === labelId)
                        ) {
                            return [
                                ...acc,
                                {
                                    templateId,
                                    labelId
                                }
                            ];
                        }
                        return acc;
                    },
                    []
                );

                if (newTemplateLabelMappings.length) {
                    const createdMappings = await createChatbotTemplateLabelMappings({
                        mappings: newTemplateLabelMappings
                    });
                    if (Array.isArray(createdMappings)) {
                        // update this.labels and this.pagedTemplates to reflect changes
                        const labels = await getChatbotFlowLabels();
                        if (Array.isArray(labels)) {
                            this.labels = labels;

                            const mappedLabel = this.labels.find(l => l.id === labelId);
                            if (
                                typeof mappedLabel === 'object' &&
                                typeof mappedLabel.id === 'string' &&
                                typeof mappedLabel.text === 'string' &&
                                typeof this.pagedTemplates === 'object' &&
                                Array.isArray(this.pagedTemplates.results)
                            ) {
                                const newMappingsMappedByTemplateId = keyBy(
                                    newTemplateLabelMappings,
                                    'templateId'
                                );
                                this.pagedTemplates = {
                                    ...this.pagedTemplates,
                                    results: this.pagedTemplates.results.map(template => {
                                        if (newMappingsMappedByTemplateId[template.id]) {
                                            return {
                                                ...template,
                                                labels: [...template.labels, mappedLabel]
                                            };
                                        }

                                        return template;
                                    })
                                };
                            }
                        }

                        this.closeLabelsModal();
                    }
                    this.labelModalData.saving = false;
                } else {
                    this.closeLabelsModal();
                }
            }
        },
        async createFlowsFromTemplates(school) {
            if (
                typeof school === 'object' &&
                typeof school.id === 'string' &&
                typeof school.name === 'string' &&
                typeof this.schoolsModalData === 'object' &&
                Array.isArray(this.schoolsModalData.templateIds)
            ) {
                try {
                    this.schoolsModalData.saving = true;
                    const created = await createFlowsFromTemplateIds({
                        schoolId: school.id,
                        templateIds: this.schoolsModalData.templateIds,
                        errorHandlerOptions: { enableAlert: true, rethrow: true }
                    });
                    if (Array.isArray(created)) {
                        const createdCount = created.length;
                        this.$Alert.alert({
                            type: 'success',
                            timeout: 7000,
                            message: `${createdCount} ${
                                createdCount > 1 ? 'flows' : 'flow'
                            } assigned to ${school.name}.`
                        });
                    }
                    this.$refs.templatesTable.clearSelectedRows();
                    this.closeSchoolsModal();
                } catch (error) {
                    this.schoolsModalData.saving = false;
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.templates {
    min-height: 100vh;
    .description {
        margin-bottom: 10px;
    }
    .box {
        min-height: 500px;
    }
}
</style>
