<template>
    <modal
        name="modal-approve-all-faq-v2"
        height="auto"
        :clickToClose="false"
        :stack="false"
        :scrollable="false"
        @before-open="beforeOpen"
        tabIndex="-1"
    >
        <div class="container">
            <h2>{{ headerDisplayText }}</h2>

            <p>{{ bodyDisplayText }}</p>

            <div class="footer">
                <Button type="button" class="cancel-btn" @click="onClose"> Cancel </Button>
                <loading-spinner-button
                    class="save-btn"
                    text="Approve"
                    :loading="loading"
                    @click="approveAllHandler()"
                />
            </div>
        </div>
    </modal>
</template>

<script>
import LoadingSpinnerButton from '@/components-deprecated/LoadingSpinnerButton.vue';
import * as FAQv2Api from '@/api/faqs-v2';

export default {
    name: 'ModalApproveAllFaqV2',
    components: { LoadingSpinnerButton },
    data() {
        return {
            loading: false,
            faq: {}
        };
    },
    props: {
        filteredFaqs: {
            type: Array,
            required: true
        },
        faqsAreFiltered: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        bodyDisplayText() {
            if (this.faqsAreFiltered === true) {
                return 'Are you sure you want to approve the selected questions and answers?';
            }

            return 'Are you sure you want to approve all questions and answers?';
        },
        headerDisplayText() {
            if (this.faqsAreFiltered === true) {
                return 'Approve Selected';
            }

            return 'Approve All';
        }
    },
    methods: {
        // noop - Vue was throwing a warning/error about this method not existing
        async beforeOpen() {},
        onClose() {
            this.$modal.hide('modal-approve-all-faq-v2');
        },
        async approveAllHandler() {
            this.loading = true;
            let response = null;

            if (this.faqsAreFiltered === true) {
                response = await FAQv2Api.approveFaqs(
                    this.$store.state.user.schoolRef.id,
                    this.filteredFaqs.map(f => f.id)
                );
            } else {
                response = await FAQv2Api.approveFaqs(this.$store.state.user.schoolRef.id);
            }

            if (response && response.friendlyMessage) {
                this.$Alert.alert({
                    type: 'warning',
                    message: response.friendlyMessage,
                    timeout: 5000
                });
            }

            this.loading = false;
            this.$emit('refresh-faqs');
            this.$modal.hide('modal-approve-all-faq-v2');
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/forms';
@import '../../styles/mixins/alerts';
@import '@/styles/variables';
@import '@/styles/mixins/buttons';

.container {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;

    .input {
        margin-bottom: 1rem;
        width: 30rem;
    }
}

.footer {
    display: flex;
    justify-content: flex-end;
    margin: 2rem 0 1rem 0;
}

.save-btn {
    @include base-button();
}

.cancel-btn {
    @include secondary-button();
    margin-right: 1rem;
}
</style>
