<template>
    <div class="faq">
        <div class="faq__question">
            {{ faq.questionText }}
        </div>
        <div class="faq__answer">
            {{ faq.answerText }}
        </div>
        <div class="faq__topic">
            {{ faq.topic }}
        </div>
        <div class="faq__subtopic">
            {{ faq.subtopic }}
        </div>

        <div class="faq__updated">
            {{ faq.friendlyUpdatedAt }}
        </div>

        <div class="faq__status">
            <LoadingSpinner v-if="toggleLoading" />
            <div v-else>
                <InputSwitch
                    :modelValue="faq.isEnabled"
                    :label="faq.isEnabled ? 'Approved' : 'Pending'"
                    @update:modelValue="setEnabled"
                    ariaLabel="Toggle FAQ as approved or pending"
                />
            </div>
        </div>

        <div class="faq__action">
            <Button
                class="faq__btn action--button"
                styleType="off"
                unbordered
                @click="onClickEditFaq"
            >
                Edit
            </Button>
            <Button
                class="faq__btn action--button"
                styleType="off"
                unbordered
                @click="onClickRemoveFaq"
            >
                Remove
            </Button>
        </div>
    </div>
</template>

<script>
import Button from '@/components-deprecated/Button';
import * as FAQv2Api from '@/api/faqs-v2';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner.vue';
import InputSwitch from '@/components-deprecated/global/v2/InputSwitch';

export default {
    name: 'Faqv2ListItem',
    components: {
        Button,
        LoadingSpinner,
        InputSwitch
    },
    data() {
        return {
            toggleLoading: false
        };
    },
    props: {
        faq: {
            type: Object,
            required: true
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    created() {},
    methods: {
        onClickEditFaq() {
            this.$modal.show('modal-add-edit-faq-v2', { faq: this.faq });
        },
        onClickRemoveFaq() {
            this.$modal.show('modal-remove-faq-v2', { faq: this.faq });
        },
        async setEnabled(enabled) {
            this.toggleLoading = true;
            try {
                await FAQv2Api.setEnabled({
                    faqId: this.faq.id,
                    enabled,
                    errorHandlerOptions: {
                        rethrow: true
                    }
                });
                this.$emit('updateFaqStatus', this.faq.id, enabled);
                this.toggleLoading = false;
            } catch (error) {
                this.toggleLoading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';

.faq {
    position: relative;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    display: table;

    & > div {
        width: 150px;
        max-width: 150px;
        padding: 0 16px;
        word-wrap: break-word;
        display: table-cell;
    }
}

.action--button {
    color: $primary-brand-color;
}
</style>
